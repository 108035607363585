import { useGetAllEngineChecksWithDetailsByAssetIdQuery } from '@flyward/assets/store'
import { StandaloneEngineExpandedDataGrid } from '../../AssetExpandedDataGrid'
import { type AssetSearchDTO } from '@flyward/assets/models'

interface IStandaloneEngineExpandedDataGridWrapperProps {
  data: AssetSearchDTO
}

const StandaloneEngineExpandedDataGridWrapper = ({ data }: IStandaloneEngineExpandedDataGridWrapperProps) => {
  const { data: engines = [] } = useGetAllEngineChecksWithDetailsByAssetIdQuery({
    assetId: data.assetId,
  })

  return <StandaloneEngineExpandedDataGrid engines={engines} />
}

export { StandaloneEngineExpandedDataGridWrapper }
