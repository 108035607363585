import {
  useGetAllAirframeChecksWithDetailsByAssetIdQuery,
  useGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQuery,
  useGetAllEngineChecksWithDetailsByAssetIdQuery,
  useGetAllLandingGearChecksWithDetailsByAssetIdQuery,
} from '@flyward/assets/store'
import { AircraftExpandedDataGrid } from '../../AssetExpandedDataGrid'
import { type AssetSearchDTO } from '@flyward/assets/models'

interface IAircraftExpandedDataGridWrapperProps {
  data: AssetSearchDTO
}

const AircraftExpandedDataGridWrapper = ({ data }: IAircraftExpandedDataGridWrapperProps) => {
  const { data: airframes = [] } = useGetAllAirframeChecksWithDetailsByAssetIdQuery({
    assetId: data.assetId,
  })
  const { data: landingGears = [] } = useGetAllLandingGearChecksWithDetailsByAssetIdQuery({
    assetId: data.assetId,
  })
  const { data: engines = [] } = useGetAllEngineChecksWithDetailsByAssetIdQuery({
    assetId: data.assetId,
  })
  const { data: auxiliaryPowerUnits = [] } = useGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQuery({
    assetId: data.assetId,
  })

  return <AircraftExpandedDataGrid airframes={airframes} landingGears={landingGears} engines={engines} auxiliaryPowerUnits={auxiliaryPowerUnits} />
}

export { AircraftExpandedDataGridWrapper }
