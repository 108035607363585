import { Button, ButtonVariant, Combobox, Dialog, IconVariant, Size, getAssetAge } from '@flyward/platform'
import { useState } from 'react'
import { type AssetSearchDTO } from '../../models/AssetSearchDTO'

interface IFilterableData {
  value: string
  label: string
}

interface IFilterableDataCollection {
  msns: IFilterableData[]
  lessees: IFilterableData[]
  aircraftModels: IFilterableData[]
  engineTypes: IFilterableData[]
  aircraftAges: IFilterableData[]
  redeliveryDates: IFilterableData[]
  regions: IFilterableData[]
  portfolio: IFilterableData[]
}

export interface IFilterData extends IFilterableDataCollection {
  selectedMsn: string[]
  selectedLessee: string[]
  selectedAircraftModel: string[]
  selectedEngineType: string[]
  selectedAircraftAge: string[]
  selectedRedeliveryDate: string[]
  selectedRegions: string[]
  selectedPortfolio: string[]
}

export const initialFilterData: IFilterData = {
  msns: [],
  lessees: [],
  aircraftModels: [],
  engineTypes: [],
  aircraftAges: [],
  redeliveryDates: [],
  regions: [],
  portfolio: [],
  selectedMsn: [],
  selectedLessee: [],
  selectedAircraftModel: [],
  selectedEngineType: [],
  selectedAircraftAge: [],
  selectedRedeliveryDate: [],
  selectedRegions: [],
  selectedPortfolio: [],
}

export const filterAssets = (assets: AssetSearchDTO[], filters: IFilterData) => {
  const filteredAircrafts = assets.filter((aircraft: AssetSearchDTO) => {
    return (
      (filters.selectedMsn.length === 0 || filters.selectedMsn.includes(aircraft.serialNumber)) &&
      (filters.selectedLessee.length === 0 || filters.selectedLessee.includes(aircraft.lessee)) &&
      (filters.selectedAircraftModel.length === 0 ||
        filters.selectedAircraftModel.includes(aircraft.aircraftModel)) &&
      (filters.selectedEngineType.length === 0 ||
        filters.selectedEngineType.includes(aircraft.engineModel)) &&
      (filters.selectedAircraftAge.length === 0 || filters.selectedAircraftAge.includes(aircraft.dom)) &&
      (filters.selectedRedeliveryDate.length === 0 ||
        filters.selectedRedeliveryDate.includes(aircraft.redeliveryDate)) &&
      (filters.selectedRegions.length === 0 || filters.selectedRegions.includes(aircraft.region)) &&
      (filters.selectedPortfolio.length === 0 || filters.selectedPortfolio.includes(aircraft.portfolio))
    )
  })

  return filteredAircrafts
}

export const initializeFilterData = (aircrafts: AssetSearchDTO[]): IFilterData => {
  const uniqueValues = (key: keyof AssetSearchDTO): string[] =>
    Array.from(new Set(aircrafts.map((item) => item[key]!.toString())))

  const mapToFilterableData = (values: string[], labelFunc = (val: string) => val): IFilterableData[] =>
    values.map((value: string): IFilterableData => ({ value, label: labelFunc(value) }))

  const uniqueMsns = uniqueValues('serialNumber')
  const uniqueLessees = uniqueValues('lessee')
  const uniqueAircraftModels = uniqueValues('aircraftModel')
  const uniqueEngineTypes = uniqueValues('engineModel')
  const uniqueAircraftAges = uniqueValues('dom')
  const uniqueRedeliveryDates = uniqueValues('redeliveryDate')
  const uniqueRegions = uniqueValues('region')
  const uniquePortfolio = uniqueValues('portfolio')

  const msns = mapToFilterableData(uniqueMsns)
  const lessees = mapToFilterableData(uniqueLessees)
  const aircraftModels = mapToFilterableData(uniqueAircraftModels)
  const engineTypes = mapToFilterableData(uniqueEngineTypes)
  const aircraftAges = mapToFilterableData(uniqueAircraftAges, getAssetAge)
  const redeliveryDates = mapToFilterableData(uniqueRedeliveryDates)
  const regions = mapToFilterableData(uniqueRegions)
  const portfolio = mapToFilterableData(uniquePortfolio)

  return {
    ...initialFilterData,
    msns,
    lessees,
    aircraftModels,
    engineTypes,
    aircraftAges,
    redeliveryDates,
    regions,
    portfolio,
  }
}

export const updateFilterSelections = (newFilters: IFilterData, prevFilters: IFilterData) => {
  const selections = {
    selectedMsn: prevFilters.selectedMsn,
    selectedLessee: prevFilters.selectedLessee,
    selectedAircraftModel: prevFilters.selectedAircraftModel,
    selectedEngineType: prevFilters.selectedEngineType,
    selectedAircraftAge: prevFilters.selectedAircraftAge,
    selectedRedeliveryDate: prevFilters.selectedRedeliveryDate,
    selectedRegions: prevFilters.selectedRegions,
    selectedPortfolio: prevFilters.selectedPortfolio,
  }

  return {
    ...newFilters,
    ...selections,
  }
}

export interface IAssetFilterDialogProps {
  applyFilter: (filterData: IFilterData) => void
  filterData: IFilterData
  availableFilterOptions: IFilterableDataCollection
}

export const AssetFilterDialog = ({
  applyFilter: applyFilterExternal,
  filterData: filterDataProps,
  availableFilterOptions,
}: IAssetFilterDialogProps) => {
  const [filterData, setFilterData] = useState<IFilterData>(filterDataProps)

  const triggerButton = ({ onClick = () => {} }) => {
    const onClickHandler = () => {
      setFilterData(filterDataProps)
      onClick()
    }

    return (
      <Button
        variant={ButtonVariant.Secondary}
        size={Size.Small}
        label="Filter"
        onClick={onClickHandler}
        leftIcon={IconVariant.FilterList}
      />
    )
  }

  const resetFilterData = () => {
    setFilterData((prevData: IFilterData) => {
      return {
        ...prevData,
        selectedMsn: availableFilterOptions.msns.map((p) => p.value),
        selectedLessee: availableFilterOptions.lessees.map((p) => p.value),
        selectedAircraftModel: availableFilterOptions.aircraftModels.map((p) => p.value),
        selectedEngineType: availableFilterOptions.engineTypes.map((p) => p.value),
        selectedAircraftAge: availableFilterOptions.aircraftAges.map((p) => p.value),
        selectedRedeliveryDate: availableFilterOptions.redeliveryDates.map((p) => p.value),
        selectedRegions: availableFilterOptions.regions.map((p) => p.value),
        selectedPortfolio: availableFilterOptions.portfolio.map((p) => p.value),
      }
    })
  }

  const applyFilter = () => {
    applyFilterExternal(filterData)
  }

  const onSelectedFilterChanged = (values: string[], key: string) => {
    setFilterData((prev: IFilterData) => ({ ...prev, [key]: values }))
  }

  return (
    <Dialog
      contentClassName="w-150"
      triggerButton={triggerButton}
      headerActions={
        <Button
          variant={ButtonVariant.Ghost}
          label="Reset All"
          onClick={resetFilterData}
          className="ml-auto font-semibold"
        />
      }
      footerActions={[
        {
          variant: ButtonVariant.Secondary,
          size: Size.Small,
          label: 'Cancel',
          onClick: resetFilterData,
          isClose: true,
        },
        {
          variant: ButtonVariant.Primary,
          size: Size.Small,
          label: 'Apply',
          onClick: applyFilter,
          isClose: true,
        },
      ]}
    >
      <div className="my-4 grid grid-cols-2 gap-x-6 gap-y-10">
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFilterOptions.msns}
          itemLabel="MSN/ESN"
          label="MSN/ESN"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterData.selectedMsn}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedMsn')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFilterOptions.lessees}
          itemLabel="Lessee"
          label="Lessee"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterData.selectedLessee}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedLessee')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFilterOptions.aircraftModels}
          itemLabel="Aircraft Model"
          label="Aircraft Model"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterData.selectedAircraftModel}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedAircraftModel')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFilterOptions.engineTypes}
          itemLabel="Engine Type"
          label="Engine Type"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterData.selectedEngineType}
          setSelectedValues={(value: string[]) => {
            setFilterData((prev: IFilterData) => ({ ...prev, selectedEngineType: value }))
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFilterOptions.aircraftAges}
          itemLabel="Aircraft Age"
          label="Aircraft Age"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterData.selectedAircraftAge}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedAircraftAge')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFilterOptions.redeliveryDates}
          itemLabel="Redelivery Date"
          label="Redelivery Date"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterData.selectedRedeliveryDate}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedRedeliveryDate')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFilterOptions.regions}
          itemLabel="Region"
          label="Region"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterData.selectedRegions}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedRegions')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFilterOptions.portfolio}
          itemLabel="Portfolio"
          label="Portfolio"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterData.selectedPortfolio}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedPortfolio')
          }}
        />
      </div>
    </Dialog>
  )
}
