import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetStandaloneEnginesByAssetIdQueryResponse } from './types'

const standaloneEnginesApiMocks = [
  http.get(APIRoutes.AssetsModule.StandAloneEngineController.GetStandAloneEngineDetails('*'), () => {
    return HttpResponse.json<IGetStandaloneEnginesByAssetIdQueryResponse>(
      {
        assetId: '9cfa4c1c-cfd0-4c21-a82c-e155f00fdb7d',
        leaseStartDate: '2021-06-11',
        leaseEndDate: '2039-10-31',
        lessee: 'Air Aviation Network SAS',
        lesseeHabitualBase: 'Indonesia',
        portfolio: 'Peregrine',
        region: 'EMEA',
      },
      { status: 200 },
    )
  }),
]

export { standaloneEnginesApiMocks }
