import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetEngineMaintenanceProgramDetailsQueryResponse } from './types'

const engineMaintenanceProgramsApiMocks = [
  http.get(APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.EnginesPrograms.GetSingleEngineProgram('*'), () => {
    return HttpResponse.json<IGetEngineMaintenanceProgramDetailsQueryResponse>({
      lifeLimitedPartPrograms: [
        {
          componentTypeInfo: {
            componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 3,
            part: 'Stage 3 Air Seal',
            createdAt: '2024-07-10T06:49:46.5793965+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793965+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
          componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 6.041,
            engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
              componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918039+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
              limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
            componentCategory: 5,
            componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 120820,
          },
          createdAt: '2024-07-16T10:48:43.9976707+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976707+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 3,
            part: 'Stage 2 Hub',
            createdAt: '2024-07-10T06:49:46.5793962+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793962+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
          componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 17.869,
            engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
              componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918029+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
              limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
            componentCategory: 5,
            componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 357380,
          },
          createdAt: '2024-07-16T10:48:43.9976791+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976791+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 3,
            part: 'LPT Shaft',
            createdAt: '2024-07-10T06:49:46.5793963+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793964+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
          componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 9.517,
            engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
              componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918034+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
              limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
            componentCategory: 5,
            componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 190340,
          },
          createdAt: '2024-07-16T10:48:43.9976797+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976797+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
            componentCategory: 5,
            module: 5,
            part: 'LP Stub Shaft',
            createdAt: '2024-07-10T06:49:46.5793946+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793947+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
          componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 3.7195,
            engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
              componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1917971+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
              limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
            componentCategory: 5,
            componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
            kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 74390,
          },
          createdAt: '2024-07-16T10:48:43.9976803+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976803+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 4,
            part: 'Stage 7 Air seal',
            createdAt: '2024-07-10T06:49:46.5793975+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793975+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
          componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 4.7595,
            engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
              componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918085+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
              limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
            componentCategory: 5,
            componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 95190,
          },
          createdAt: '2024-07-16T10:48:43.997681+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.997681+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 3,
            part: 'Stage 2 Blade Retention Plate',
            createdAt: '2024-07-10T06:49:46.5793961+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793961+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
          componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 7.383,
            engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
              componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918025+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
              limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
            componentCategory: 5,
            componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 147660,
          },
          createdAt: '2024-07-16T10:48:43.9976822+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976822+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 2,
            part: 'Stage 3-8 Rotor Drum',
            createdAt: '2024-07-10T06:49:46.5793952+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793952+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
          componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 21.727,
            engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
              componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1917984+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
              limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
            componentCategory: 5,
            componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 434540,
          },
          createdAt: '2024-07-16T10:48:43.9976828+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976829+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 3,
            part: 'Stage 2 Air Seal',
            createdAt: '2024-07-10T06:49:46.579396+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.579396+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
          componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 14.0445,
            engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
              componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918019+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
              limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
            componentCategory: 5,
            componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 280890,
          },
          createdAt: '2024-07-16T10:48:43.9976834+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976834+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 3,
            part: 'Stage 1 Hub',
            createdAt: '2024-07-10T06:49:46.5793957+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793957+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
          componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 22.9945,
            engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
              componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918005+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
              limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
            componentCategory: 5,
            componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 459890,
          },
          createdAt: '2024-07-16T10:48:43.997684+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.997684+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 4,
            part: 'Stage 6 Disk',
            createdAt: '2024-07-10T06:49:46.5793972+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793973+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
          componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 12.5955,
            engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
              componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918075+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
              limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
            componentCategory: 5,
            componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 251910,
          },
          createdAt: '2024-07-16T10:48:43.9976846+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976846+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 4,
            part: 'Stage 6 Inner Air Seal',
            createdAt: '2024-07-10T06:49:46.5793974+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793974+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
          componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 2.7165,
            engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
              componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918081+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
              limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
            componentCategory: 5,
            componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 54330,
          },
          createdAt: '2024-07-16T10:48:43.9976852+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976852+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 4,
            part: 'Stage 5 Disk',
            createdAt: '2024-07-10T06:49:46.579397+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.579397+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
          componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 8.612,
            engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
              componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918065+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
              limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
            componentCategory: 5,
            componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 172240,
          },
          createdAt: '2024-07-16T10:48:43.997686+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976861+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 5,
            part: '1.5-2.5 Disk',
            createdAt: '2024-07-10T06:49:46.579395+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793951+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
          componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 25.6435,
            engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
              componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1917979+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
              limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
            componentCategory: 5,
            componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 512870,
          },
          createdAt: '2024-07-16T10:48:43.9976866+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976866+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 3,
            part: 'Stage 3 Disk',
            createdAt: '2024-07-10T06:49:46.5793966+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793966+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
          componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 7.827,
            engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
              componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918045+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
              limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
            componentCategory: 5,
            componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 156540,
          },
          createdAt: '2024-07-16T10:48:43.9976872+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976872+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 4,
            part: 'Stage 4 Air Seal',
            createdAt: '2024-07-10T06:49:46.5793967+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793967+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
          componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 10.591,
            engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
              componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918049+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
              limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
            componentCategory: 5,
            componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 211820,
          },
          createdAt: '2024-07-16T10:48:43.9976877+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976877+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 4,
            part: 'Stage 5 Air Seal',
            createdAt: '2024-07-10T06:49:46.5793969+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.579397+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
          componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 10.3495,
            engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
              componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918061+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
              limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
            componentCategory: 5,
            componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 206990,
          },
          createdAt: '2024-07-16T10:48:43.9976883+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976883+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 4,
            part: 'Stage 6 Air Seal',
            createdAt: '2024-07-10T06:49:46.5793972+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793972+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
          componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 5.4185,
            engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
              componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.191807+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
              limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
            componentCategory: 5,
            componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 108370,
          },
          createdAt: '2024-07-16T10:48:43.9976888+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976889+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 4,
            part: 'Stage 7 Disk',
            createdAt: '2024-07-10T06:49:46.5793976+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793976+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
          componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 9.2495,
            engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
              componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.191809+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
              limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
            componentCategory: 5,
            componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 184990,
          },
          createdAt: '2024-07-16T10:48:43.9976896+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976896+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
            componentCategory: 5,
            module: 1,
            part: 'Fan Disk Stage 1',
            createdAt: '2024-07-10T06:49:46.5792823+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5792824+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
          componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 15.282,
            engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
              componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1916418+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
              limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
            componentCategory: 5,
            componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
            kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 305640,
          },
          createdAt: '2024-07-16T10:48:43.9976901+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976901+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 3,
            part: 'Stage 1 Outer Air Seal',
            createdAt: '2024-07-10T06:49:46.5793959+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793959+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
          componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 10.5635,
            engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
              componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918015+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
              limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
            componentCategory: 5,
            componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 211270,
          },
          createdAt: '2024-07-16T10:48:43.9976907+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976907+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 2,
            part: 'HPC Rear Stub Shaft',
            createdAt: '2024-07-10T06:49:46.5793955+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793955+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
          componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 12.3365,
            engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
              componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
              limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
            componentCategory: 5,
            componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 246730,
          },
          createdAt: '2024-07-16T10:48:43.9976912+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976912+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 3,
            part: 'Stage 1 Inner Air Seal',
            createdAt: '2024-07-10T06:49:46.5793958+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793958+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
          componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 4.322,
            engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
              componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.191801+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
              limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
            componentCategory: 5,
            componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 86440,
          },
          createdAt: '2024-07-16T10:48:43.9976917+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976917+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 4,
            part: 'Disk 4',
            createdAt: '2024-07-10T06:49:46.5793968+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793968+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
          componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 9.4235,
            engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
              componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1918054+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
              limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
            componentCategory: 5,
            componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 188470,
          },
          createdAt: '2024-07-16T10:48:43.9976922+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976923+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 2,
            part: 'HPC Rear Rotating Seal',
            createdAt: '2024-07-10T06:49:46.5793954+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793954+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
          componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 3.302,
            engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
              componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  null,
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                    componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 35.4135,
                      engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                        limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '570cc030-7666-4324-ba24-532c35386bba',
                      componentCategory: 5,
                      componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 708270,
                    },
                    createdAt: '2024-07-10T06:50:07.191799+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
                  },
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.1917995+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
              limitId: '08591d23-9514-4b01-ad55-b2102320387c',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
            componentCategory: 5,
            componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 66040,
          },
          createdAt: '2024-07-16T10:48:43.9976929+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976929+00:00',
        },
        {
          componentTypeInfo: {
            componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
            componentCategory: 5,
            module: 2,
            part: 'Stage 9-12 Rotor Drum',
            createdAt: '2024-07-10T06:49:46.5793953+00:00',
            lastModifiedAt: '2024-07-10T06:49:46.5793953+00:00',
          },
          engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
          componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          check: {
            costPerCycle: 35.4135,
            engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
            engineLifeLimitedPartMaintenanceProgram: {
              engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
              componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              engineMaintenanceProgram: {
                enginePerformanceRestorationMaintenanceProgram: {
                  enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                  check: {
                    enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    enginePerformanceRestorationCheckLimits: [
                      {
                        checkLimitType: 2,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                        utilizationUnit: 2,
                        utilization: 20000,
                      },
                      {
                        checkLimitType: 1,
                        enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                        limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                        utilizationUnit: 2,
                        utilization: 15000,
                      },
                    ],
                    checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    componentCategory: 4,
                    componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                    kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    kbReferenceYear: 2024,
                    checkType: 1,
                    utilizationUnit: 2,
                    baseCost: 4039529.69,
                  },
                  createdAt: '2024-07-10T06:50:07.154593+00:00',
                  lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
                },
                lifeLimitedPartPrograms: [
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                    componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 6.041,
                      engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                        limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                      componentCategory: 5,
                      componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 120820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918039+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                    componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 17.869,
                      engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                        limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                      componentCategory: 5,
                      componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 357380,
                    },
                    createdAt: '2024-07-10T06:50:07.1918029+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                    componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.517,
                      engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                        limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                      componentCategory: 5,
                      componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 190340,
                    },
                    createdAt: '2024-07-10T06:50:07.1918034+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                    componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.7195,
                      engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                        limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                      componentCategory: 5,
                      componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                      kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 74390,
                    },
                    createdAt: '2024-07-10T06:50:07.1917971+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                    componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.7595,
                      engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                        limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                      componentCategory: 5,
                      componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 95190,
                    },
                    createdAt: '2024-07-10T06:50:07.1918085+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                    componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.383,
                      engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                        limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                      componentCategory: 5,
                      componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 147660,
                    },
                    createdAt: '2024-07-10T06:50:07.1918025+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                    componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 21.727,
                      engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                        limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                      componentCategory: 5,
                      componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 434540,
                    },
                    createdAt: '2024-07-10T06:50:07.1917984+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                    componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 14.0445,
                      engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                        limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                      componentCategory: 5,
                      componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 280890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918019+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                    componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 22.9945,
                      engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                        limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                      componentCategory: 5,
                      componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 459890,
                    },
                    createdAt: '2024-07-10T06:50:07.1918005+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                    componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.5955,
                      engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                        limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                      componentCategory: 5,
                      componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 251910,
                    },
                    createdAt: '2024-07-10T06:50:07.1918075+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                    componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 2.7165,
                      engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                        limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                      componentCategory: 5,
                      componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 54330,
                    },
                    createdAt: '2024-07-10T06:50:07.1918081+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                    componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 8.612,
                      engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                        limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                      componentCategory: 5,
                      componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 172240,
                    },
                    createdAt: '2024-07-10T06:50:07.1918065+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                    componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 25.6435,
                      engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                        limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                      componentCategory: 5,
                      componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 512870,
                    },
                    createdAt: '2024-07-10T06:50:07.1917979+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                    componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 7.827,
                      engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                        limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                      componentCategory: 5,
                      componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 156540,
                    },
                    createdAt: '2024-07-10T06:50:07.1918045+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                    componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.591,
                      engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                        limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                      componentCategory: 5,
                      componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211820,
                    },
                    createdAt: '2024-07-10T06:50:07.1918049+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                    componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.3495,
                      engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                        limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                      componentCategory: 5,
                      componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 206990,
                    },
                    createdAt: '2024-07-10T06:50:07.1918061+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                    componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 5.4185,
                      engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                        limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                      componentCategory: 5,
                      componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 108370,
                    },
                    createdAt: '2024-07-10T06:50:07.191807+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                    componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.2495,
                      engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                        limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                      componentCategory: 5,
                      componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 184990,
                    },
                    createdAt: '2024-07-10T06:50:07.191809+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                    componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 15.282,
                      engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                        limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                      componentCategory: 5,
                      componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                      kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 305640,
                    },
                    createdAt: '2024-07-10T06:50:07.1916418+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                    componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 10.5635,
                      engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                        limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                      componentCategory: 5,
                      componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 211270,
                    },
                    createdAt: '2024-07-10T06:50:07.1918015+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                    componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 12.3365,
                      engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                        limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                      componentCategory: 5,
                      componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 246730,
                    },
                    createdAt: '2024-07-10T06:50:07.1918+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                    componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 4.322,
                      engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                        limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                      componentCategory: 5,
                      componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 86440,
                    },
                    createdAt: '2024-07-10T06:50:07.191801+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                    componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 9.4235,
                      engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                        limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                      componentCategory: 5,
                      componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 188470,
                    },
                    createdAt: '2024-07-10T06:50:07.1918054+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
                  },
                  {
                    engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                    componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    check: {
                      costPerCycle: 3.302,
                      engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      lifeLimitedPartCheckLimit: {
                        lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                        limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                        utilizationUnit: 3,
                        utilization: 20000,
                      },
                      checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                      componentCategory: 5,
                      componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                      kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                      kbReferenceYear: 2024,
                      checkType: 2,
                      utilizationUnit: 3,
                      baseCost: 66040,
                    },
                    createdAt: '2024-07-10T06:50:07.1917995+00:00',
                    lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
                  },
                  null,
                ],
                totalLLPsCost: 5834020,
                totalLLPsCostPerCycle: 291.701,
                environmentalImpacts: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 4,
                    percentage: -35,
                    id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 3,
                    percentage: -30,
                    id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 2,
                    percentage: -20,
                    id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    environmentalImpactType: 1,
                    percentage: 0,
                    id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
                  },
                ],
                derateRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 0,
                    intervalEnd: 5,
                    percentage: 0,
                    id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 10,
                    intervalEnd: 15,
                    percentage: 15,
                    id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 5,
                    intervalEnd: 10,
                    percentage: 8,
                    id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 15,
                    intervalEnd: 20,
                    percentage: 22,
                    id: '1b648310-587f-426d-9e13-d201378a2550',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 25,
                    intervalEnd: 100,
                    percentage: 33,
                    id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    intervalStart: 20,
                    intervalEnd: 25,
                    percentage: 28,
                    id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
                  },
                ],
                stubLife: {
                  engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  intervalStart: 0,
                  intervalEnd: 15,
                  id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
                },
                flightHoursFlightCyclesRatios: [
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '3:1',
                    firstRunLimit: 45626,
                    matureLimit: 41824,
                    id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2:1',
                    firstRunLimit: 34500,
                    matureLimit: 31625,
                    id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                    firstRunLimit: 39675,
                    matureLimit: 36369,
                    id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                    firstRunLimit: 21675,
                    matureLimit: 19869,
                    id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1:1',
                    firstRunLimit: 25500,
                    matureLimit: 23375,
                    id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
                  },
                  {
                    engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                    firstRunLimit: 30000,
                    matureLimit: 27500,
                    id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
                  },
                ],
                maintenanceProgramName: 'IAE - V2527-A5 - 2024',
                isActive: true,
                dateOfCreation: '2024-07-10T06:50:07.1082445Z',
                kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentCategory: 2,

                compatibleComponentModels: ['V2527-A5'],
                kbReferenceYear: 2024,
                originalEquipmentManufacturer: 'IAE',
                inflation: [
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 5,
                    referenceYear: 2024,
                    percentage: 5,
                    id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
                  },
                  {
                    maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                    componentType: 4,
                    referenceYear: 2023,
                    percentage: 4,
                    id: '17c43787-b690-4a2c-8060-d3878abd033f',
                  },
                ],
                basicAssumptions: {
                  maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                  checkDowntimeMonths: 2,
                  monthsDelayInPaymentOnClaims: 1,
                  id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
                },
                createdAt: '2024-07-10T06:50:07.1082588+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
              },
              createdAt: '2024-07-10T06:50:07.191799+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
            },
            lifeLimitedPartCheckLimit: {
              lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
              limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
              utilizationUnit: 3,
              utilization: 20000,
            },
            checkId: '570cc030-7666-4324-ba24-532c35386bba',
            componentCategory: 5,
            componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
            kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
            kbReferenceYear: 2024,
            checkType: 2,
            utilizationUnit: 3,
            baseCost: 708270,
          },
          createdAt: '2024-07-16T10:48:43.9976934+00:00',
          lastModifiedAt: '2024-07-16T10:48:43.9976935+00:00',
        },
      ],
      enginePerformanceRestorationMaintenanceProgram: {
        enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
        engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
        engineMaintenanceProgram: {
          lifeLimitedPartPrograms: [
            {
              engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
              componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 6.041,
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                componentCategory: 5,
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 120820,
              },
              createdAt: '2024-07-10T06:50:07.1918039+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
              componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 17.869,
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                componentCategory: 5,
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 357380,
              },
              createdAt: '2024-07-10T06:50:07.1918029+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
              componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 9.517,
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                componentCategory: 5,
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 190340,
              },
              createdAt: '2024-07-10T06:50:07.1918034+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
              componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 3.7195,
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                componentCategory: 5,
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 74390,
              },
              createdAt: '2024-07-10T06:50:07.1917971+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
              componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 4.7595,
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                componentCategory: 5,
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 95190,
              },
              createdAt: '2024-07-10T06:50:07.1918085+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
              componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 7.383,
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                componentCategory: 5,
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 147660,
              },
              createdAt: '2024-07-10T06:50:07.1918025+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
              componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 21.727,
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                componentCategory: 5,
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 434540,
              },
              createdAt: '2024-07-10T06:50:07.1917984+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
              componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 14.0445,
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                componentCategory: 5,
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 280890,
              },
              createdAt: '2024-07-10T06:50:07.1918019+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
              componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 22.9945,
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                componentCategory: 5,
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 459890,
              },
              createdAt: '2024-07-10T06:50:07.1918005+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
              componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 12.5955,
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                componentCategory: 5,
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 251910,
              },
              createdAt: '2024-07-10T06:50:07.1918075+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
              componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 2.7165,
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                componentCategory: 5,
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 54330,
              },
              createdAt: '2024-07-10T06:50:07.1918081+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
              componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 8.612,
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                componentCategory: 5,
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 172240,
              },
              createdAt: '2024-07-10T06:50:07.1918065+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
              componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 25.6435,
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                componentCategory: 5,
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 512870,
              },
              createdAt: '2024-07-10T06:50:07.1917979+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
              componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 7.827,
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                componentCategory: 5,
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 156540,
              },
              createdAt: '2024-07-10T06:50:07.1918045+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
              componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 10.591,
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                componentCategory: 5,
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 211820,
              },
              createdAt: '2024-07-10T06:50:07.1918049+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
              componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 10.3495,
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                componentCategory: 5,
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 206990,
              },
              createdAt: '2024-07-10T06:50:07.1918061+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
              componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 5.4185,
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                componentCategory: 5,
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 108370,
              },
              createdAt: '2024-07-10T06:50:07.191807+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
              componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 9.2495,
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                componentCategory: 5,
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 184990,
              },
              createdAt: '2024-07-10T06:50:07.191809+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
              componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 15.282,
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                componentCategory: 5,
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 305640,
              },
              createdAt: '2024-07-10T06:50:07.1916418+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
              componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 10.5635,
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                componentCategory: 5,
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 211270,
              },
              createdAt: '2024-07-10T06:50:07.1918015+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
              componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 12.3365,
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                componentCategory: 5,
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 246730,
              },
              createdAt: '2024-07-10T06:50:07.1918+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
              componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 4.322,
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                componentCategory: 5,
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 86440,
              },
              createdAt: '2024-07-10T06:50:07.191801+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
              componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 9.4235,
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                componentCategory: 5,
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 188470,
              },
              createdAt: '2024-07-10T06:50:07.1918054+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
              componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 3.302,
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                componentCategory: 5,
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 66040,
              },
              createdAt: '2024-07-10T06:50:07.1917995+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
              componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 35.4135,
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                  limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '570cc030-7666-4324-ba24-532c35386bba',
                componentCategory: 5,
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 708270,
              },
              createdAt: '2024-07-10T06:50:07.191799+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
            },
          ],
          totalLLPsCost: 5834020,
          totalLLPsCostPerCycle: 291.701,
          environmentalImpacts: [
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              environmentalImpactType: 4,
              percentage: -35,
              id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              environmentalImpactType: 3,
              percentage: -30,
              id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              environmentalImpactType: 2,
              percentage: -20,
              id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              environmentalImpactType: 1,
              percentage: 0,
              id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
            },
          ],
          derateRatios: [
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 5,
              percentage: 0,
              id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 10,
              intervalEnd: 15,
              percentage: 15,
              id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 5,
              intervalEnd: 10,
              percentage: 8,
              id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 15,
              intervalEnd: 20,
              percentage: 22,
              id: '1b648310-587f-426d-9e13-d201378a2550',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 25,
              intervalEnd: 100,
              percentage: 33,
              id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 20,
              intervalEnd: 25,
              percentage: 28,
              id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
            },
          ],
          stubLife: {
            engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            intervalStart: 0,
            intervalEnd: 15,
            id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
          },
          flightHoursFlightCyclesRatios: [
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              flightHoursFlightCyclesRatioIdentifier: '3:1',
              firstRunLimit: 45626,
              matureLimit: 41824,
              id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              flightHoursFlightCyclesRatioIdentifier: '2:1',
              firstRunLimit: 34500,
              matureLimit: 31625,
              id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              flightHoursFlightCyclesRatioIdentifier: '2.5:1',
              firstRunLimit: 39675,
              matureLimit: 36369,
              id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
              firstRunLimit: 21675,
              matureLimit: 19869,
              id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              flightHoursFlightCyclesRatioIdentifier: '1:1',
              firstRunLimit: 25500,
              matureLimit: 23375,
              id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              flightHoursFlightCyclesRatioIdentifier: '1.5:1',
              firstRunLimit: 30000,
              matureLimit: 27500,
              id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
            },
          ],
          maintenanceProgramName: 'IAE - V2527-A5 - 2024',
          isActive: true,
          dateOfCreation: '2024-07-10T06:50:07.1082445Z',
          kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          componentCategory: 2,

          compatibleComponentModels: ['V2527-A5'],
          kbReferenceYear: 2024,
          originalEquipmentManufacturer: 'IAE',
          inflation: [
            {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentType: 5,
              referenceYear: 2024,
              percentage: 5,
              id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
            },
            {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentType: 4,
              referenceYear: 2023,
              percentage: 4,
              id: '17c43787-b690-4a2c-8060-d3878abd033f',
            },
          ],
          basicAssumptions: {
            maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            checkDowntimeMonths: 2,
            monthsDelayInPaymentOnClaims: 1,
            id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
          },
          createdAt: '2024-07-10T06:50:07.1082588+00:00',
          lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
        },
        componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
        check: {
          enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          enginePerformanceRestorationCheckLimits: [
            {
              checkLimitType: 2,
              enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
              utilizationUnit: 2,
              utilization: 20000,
            },
            {
              checkLimitType: 1,
              enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
              utilizationUnit: 2,
              utilization: 15000,
            },
          ],
          checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
          componentCategory: 4,
          componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
          kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
          kbReferenceYear: 2024,
          checkType: 1,
          utilizationUnit: 2,
          baseCost: 4039529.69,
        },
        createdAt: '2024-07-10T06:50:07.154593+00:00',
        lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
      },
      environmentalImpacts: [
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          environmentalImpactType: 4,
          percentage: -35,
          id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
        },
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          environmentalImpactType: 3,
          percentage: -30,
          id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
        },
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          environmentalImpactType: 2,
          percentage: -20,
          id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
        },
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          environmentalImpactType: 1,
          percentage: 0,
          id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
        },
      ],
      derateRatios: [
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          intervalStart: 0,
          intervalEnd: 5,
          percentage: 0,
          id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
        },
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          intervalStart: 10,
          intervalEnd: 15,
          percentage: 15,
          id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
        },
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          intervalStart: 5,
          intervalEnd: 10,
          percentage: 8,
          id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
        },
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          intervalStart: 15,
          intervalEnd: 20,
          percentage: 22,
          id: '1b648310-587f-426d-9e13-d201378a2550',
        },
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          intervalStart: 25,
          intervalEnd: 100,
          percentage: 33,
          id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
        },
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            flightHoursFlightCyclesRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '3:1',
                firstRunLimit: 45626,
                matureLimit: 41824,
                id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2:1',
                firstRunLimit: 34500,
                matureLimit: 31625,
                id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '2.5:1',
                firstRunLimit: 39675,
                matureLimit: 36369,
                id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
                firstRunLimit: 21675,
                matureLimit: 19869,
                id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1:1',
                firstRunLimit: 25500,
                matureLimit: 23375,
                id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                flightHoursFlightCyclesRatioIdentifier: '1.5:1',
                firstRunLimit: 30000,
                matureLimit: 27500,
                id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
              },
            ],
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          intervalStart: 20,
          intervalEnd: 25,
          percentage: 28,
          id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
        },
      ],
      stubLife: {
        engineMaintenanceProgram: {
          enginePerformanceRestorationMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
            engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
            check: {
              enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              enginePerformanceRestorationCheckLimits: [
                {
                  checkLimitType: 2,
                  enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                  utilizationUnit: 2,
                  utilization: 20000,
                },
                {
                  checkLimitType: 1,
                  enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                  limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                  utilizationUnit: 2,
                  utilization: 15000,
                },
              ],
              checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              componentCategory: 4,
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              kbReferenceYear: 2024,
              checkType: 1,
              utilizationUnit: 2,
              baseCost: 4039529.69,
            },
            createdAt: '2024-07-10T06:50:07.154593+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
          },
          lifeLimitedPartPrograms: [
            {
              engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
              componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 6.041,
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                componentCategory: 5,
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 120820,
              },
              createdAt: '2024-07-10T06:50:07.1918039+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
              componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 17.869,
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                componentCategory: 5,
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 357380,
              },
              createdAt: '2024-07-10T06:50:07.1918029+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
              componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 9.517,
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                componentCategory: 5,
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 190340,
              },
              createdAt: '2024-07-10T06:50:07.1918034+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
              componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 3.7195,
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                componentCategory: 5,
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 74390,
              },
              createdAt: '2024-07-10T06:50:07.1917971+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
              componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 4.7595,
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                componentCategory: 5,
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 95190,
              },
              createdAt: '2024-07-10T06:50:07.1918085+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
              componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 7.383,
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                componentCategory: 5,
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 147660,
              },
              createdAt: '2024-07-10T06:50:07.1918025+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
              componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 21.727,
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                componentCategory: 5,
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 434540,
              },
              createdAt: '2024-07-10T06:50:07.1917984+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
              componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 14.0445,
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                componentCategory: 5,
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 280890,
              },
              createdAt: '2024-07-10T06:50:07.1918019+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
              componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 22.9945,
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                componentCategory: 5,
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 459890,
              },
              createdAt: '2024-07-10T06:50:07.1918005+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
              componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 12.5955,
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                componentCategory: 5,
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 251910,
              },
              createdAt: '2024-07-10T06:50:07.1918075+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
              componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 2.7165,
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                componentCategory: 5,
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 54330,
              },
              createdAt: '2024-07-10T06:50:07.1918081+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
              componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 8.612,
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                componentCategory: 5,
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 172240,
              },
              createdAt: '2024-07-10T06:50:07.1918065+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
              componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 25.6435,
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                componentCategory: 5,
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 512870,
              },
              createdAt: '2024-07-10T06:50:07.1917979+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
              componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 7.827,
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                componentCategory: 5,
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 156540,
              },
              createdAt: '2024-07-10T06:50:07.1918045+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
              componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 10.591,
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                componentCategory: 5,
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 211820,
              },
              createdAt: '2024-07-10T06:50:07.1918049+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
              componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 10.3495,
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                componentCategory: 5,
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 206990,
              },
              createdAt: '2024-07-10T06:50:07.1918061+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
              componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 5.4185,
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                componentCategory: 5,
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 108370,
              },
              createdAt: '2024-07-10T06:50:07.191807+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
              componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 9.2495,
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                componentCategory: 5,
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 184990,
              },
              createdAt: '2024-07-10T06:50:07.191809+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
              componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 15.282,
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                componentCategory: 5,
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 305640,
              },
              createdAt: '2024-07-10T06:50:07.1916418+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
              componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 10.5635,
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                componentCategory: 5,
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 211270,
              },
              createdAt: '2024-07-10T06:50:07.1918015+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
              componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 12.3365,
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                componentCategory: 5,
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 246730,
              },
              createdAt: '2024-07-10T06:50:07.1918+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
              componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 4.322,
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                componentCategory: 5,
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 86440,
              },
              createdAt: '2024-07-10T06:50:07.191801+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
              componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 9.4235,
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                componentCategory: 5,
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 188470,
              },
              createdAt: '2024-07-10T06:50:07.1918054+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
              componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 3.302,
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                componentCategory: 5,
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 66040,
              },
              createdAt: '2024-07-10T06:50:07.1917995+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
            },
            {
              engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
              componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              check: {
                costPerCycle: 35.4135,
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                lifeLimitedPartCheckLimit: {
                  lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                  limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                  utilizationUnit: 3,
                  utilization: 20000,
                },
                checkId: '570cc030-7666-4324-ba24-532c35386bba',
                componentCategory: 5,
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                kbReferenceYear: 2024,
                checkType: 2,
                utilizationUnit: 3,
                baseCost: 708270,
              },
              createdAt: '2024-07-10T06:50:07.191799+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
            },
          ],
          totalLLPsCost: 5834020,
          totalLLPsCostPerCycle: 291.701,
          environmentalImpacts: [
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              environmentalImpactType: 4,
              percentage: -35,
              id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              environmentalImpactType: 3,
              percentage: -30,
              id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              environmentalImpactType: 2,
              percentage: -20,
              id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              environmentalImpactType: 1,
              percentage: 0,
              id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
            },
          ],
          derateRatios: [
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 5,
              percentage: 0,
              id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 10,
              intervalEnd: 15,
              percentage: 15,
              id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 5,
              intervalEnd: 10,
              percentage: 8,
              id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 15,
              intervalEnd: 20,
              percentage: 22,
              id: '1b648310-587f-426d-9e13-d201378a2550',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 25,
              intervalEnd: 100,
              percentage: 33,
              id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 20,
              intervalEnd: 25,
              percentage: 28,
              id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
            },
          ],
          flightHoursFlightCyclesRatios: [
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              flightHoursFlightCyclesRatioIdentifier: '3:1',
              firstRunLimit: 45626,
              matureLimit: 41824,
              id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              flightHoursFlightCyclesRatioIdentifier: '2:1',
              firstRunLimit: 34500,
              matureLimit: 31625,
              id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              flightHoursFlightCyclesRatioIdentifier: '2.5:1',
              firstRunLimit: 39675,
              matureLimit: 36369,
              id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
              firstRunLimit: 21675,
              matureLimit: 19869,
              id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              flightHoursFlightCyclesRatioIdentifier: '1:1',
              firstRunLimit: 25500,
              matureLimit: 23375,
              id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
            },
            {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              flightHoursFlightCyclesRatioIdentifier: '1.5:1',
              firstRunLimit: 30000,
              matureLimit: 27500,
              id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
            },
          ],
          maintenanceProgramName: 'IAE - V2527-A5 - 2024',
          isActive: true,
          dateOfCreation: '2024-07-10T06:50:07.1082445Z',
          kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          componentCategory: 2,

          compatibleComponentModels: ['V2527-A5'],
          kbReferenceYear: 2024,
          originalEquipmentManufacturer: 'IAE',
          inflation: [
            {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentType: 5,
              referenceYear: 2024,
              percentage: 5,
              id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
            },
            {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentType: 4,
              referenceYear: 2023,
              percentage: 4,
              id: '17c43787-b690-4a2c-8060-d3878abd033f',
            },
          ],
          basicAssumptions: {
            maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            checkDowntimeMonths: 2,
            monthsDelayInPaymentOnClaims: 1,
            id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
          },
          createdAt: '2024-07-10T06:50:07.1082588+00:00',
          lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
        },
        engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
        intervalStart: 0,
        intervalEnd: 15,
        id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
      },
      flightHoursFlightCyclesRatios: [
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          flightHoursFlightCyclesRatioIdentifier: '3:1',
          firstRunLimit: 45626,
          matureLimit: 41824,
          id: '8a9f8f0c-5f1f-43ac-a8de-5f6a070ce4dd',
        },
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          flightHoursFlightCyclesRatioIdentifier: '2:1',
          firstRunLimit: 34500,
          matureLimit: 31625,
          id: '72be9250-439b-4b76-aac5-6f8aa3eed237',
        },
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          flightHoursFlightCyclesRatioIdentifier: '2.5:1',
          firstRunLimit: 39675,
          matureLimit: 36369,
          id: '4eefc2b6-9961-4bd2-897e-825aa82e83d3',
        },
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          flightHoursFlightCyclesRatioIdentifier: 'Less than 1:1',
          firstRunLimit: 21675,
          matureLimit: 19869,
          id: '68f21005-8121-4a07-9ce2-adcc451ed2f5',
        },
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          flightHoursFlightCyclesRatioIdentifier: '1:1',
          firstRunLimit: 25500,
          matureLimit: 23375,
          id: '9c7cb6b0-7e35-4410-aab7-c77e9619cae4',
        },
        {
          engineMaintenanceProgram: {
            enginePerformanceRestorationMaintenanceProgram: {
              enginePerformanceRestorationMaintenanceProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
              check: {
                enginePerformanceRestorationMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                enginePerformanceRestorationCheckLimits: [
                  {
                    checkLimitType: 2,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '9f9c5042-1ac6-41af-afc3-65f5a570c448',
                    utilizationUnit: 2,
                    utilization: 20000,
                  },
                  {
                    checkLimitType: 1,
                    enginePerformanceRestorationCheckId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                    limitId: '6d499e1b-b282-4897-b7bf-b687fc972a84',
                    utilizationUnit: 2,
                    utilization: 15000,
                  },
                ],
                checkId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                componentCategory: 4,
                componentTypeId: '4aaaaaaa-b833-427c-8e00-cfddb4be135c',
                kbProgramId: '4ac05bc1-422f-4fc6-8b5a-adef688a2cea',
                kbReferenceYear: 2024,
                checkType: 1,
                utilizationUnit: 2,
                baseCost: 4039529.69,
              },
              createdAt: '2024-07-10T06:50:07.154593+00:00',
              lastModifiedAt: '2024-07-10T06:50:07.1545931+00:00',
            },
            lifeLimitedPartPrograms: [
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 6.041,
                  engineLifeLimitedPartMaintenanceProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                    limitId: '636198f1-ffc0-47a3-98f6-a4c7bb71a6e9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd5640d2f-2c15-4ef1-b789-315391dbed7d',
                  componentCategory: 5,
                  componentTypeId: '50015aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a9fca2c-ed27-41a3-ad01-126d13be6b3d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 120820,
                },
                createdAt: '2024-07-10T06:50:07.1918039+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918039+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 17.869,
                  engineLifeLimitedPartMaintenanceProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                    limitId: 'cb1509c6-2816-464a-a54c-e9201f7e1506',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '11267dc6-8b22-42c2-89f8-78bc859f4815',
                  componentCategory: 5,
                  componentTypeId: '50013aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ace3ea29-93c7-4e78-a415-21111855d10d',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 357380,
                },
                createdAt: '2024-07-10T06:50:07.1918029+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191803+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.517,
                  engineLifeLimitedPartMaintenanceProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                    limitId: '23f92411-07e9-44b8-ada8-e794c63578c0',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'dff836d0-1902-422e-8e52-bbb4171ea3da',
                  componentCategory: 5,
                  componentTypeId: '50014aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3b005a18-098a-4c42-b9d4-332a8ec35baf',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 190340,
                },
                createdAt: '2024-07-10T06:50:07.1918034+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918034+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.7195,
                  engineLifeLimitedPartMaintenanceProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                    limitId: '6795b4b8-9e50-4167-8284-04c68fbc1b10',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '83cfdae5-a922-4fc7-a05f-2378fa4a67de',
                  componentCategory: 5,
                  componentTypeId: '50002aaa-3d67-4c77-c8ac-073b8a91db2f',
                  kbProgramId: 'bc6cdd8d-9034-4128-a676-3fbe5f5d63c9',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 74390,
                },
                createdAt: '2024-07-10T06:50:07.1917971+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917972+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.7595,
                  engineLifeLimitedPartMaintenanceProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                    limitId: '0533a4b8-f053-4cb4-974c-01b64f08e265',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'd6316e19-182e-4455-911b-9b2987cd4b94',
                  componentCategory: 5,
                  componentTypeId: '50024aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'edbcbb0d-444c-4d42-849b-51e6caef551c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 95190,
                },
                createdAt: '2024-07-10T06:50:07.1918085+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918086+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.383,
                  engineLifeLimitedPartMaintenanceProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                    limitId: '453c7929-39fb-44db-b9e1-3d627166a297',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '5931f402-0a48-44da-9d83-8af861dd8e18',
                  componentCategory: 5,
                  componentTypeId: '50012aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '6a9879ae-528f-415e-8adb-52cd1e4a2d71',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 147660,
                },
                createdAt: '2024-07-10T06:50:07.1918025+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918025+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 21.727,
                  engineLifeLimitedPartMaintenanceProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                    limitId: '4c953659-3b0b-4139-8151-b70f937c7427',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'fcf204d9-f757-453c-b328-57cc8894a32c',
                  componentCategory: 5,
                  componentTypeId: '50004aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '2f99f731-4ff0-4588-816c-5deb7f519e19',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 434540,
                },
                createdAt: '2024-07-10T06:50:07.1917984+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917984+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 14.0445,
                  engineLifeLimitedPartMaintenanceProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                    limitId: 'f09a2ec1-7fc2-4b8a-9960-fa13c5f49df9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a2bcc1df-a6bd-4532-ac05-fc413547d2c5',
                  componentCategory: 5,
                  componentTypeId: '50011aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '066ba614-7352-4305-8ac9-6038847b0a04',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 280890,
                },
                createdAt: '2024-07-10T06:50:07.1918019+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918019+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 22.9945,
                  engineLifeLimitedPartMaintenanceProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                    limitId: 'c0c5ebdb-8e9c-40be-a7c2-f4769cc20cf9',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6dee99a5-2667-40ca-a380-feccdc71c5fc',
                  componentCategory: 5,
                  componentTypeId: '50008aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '4c8ee441-8ed6-4a2d-8db0-61684752fd0f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 459890,
                },
                createdAt: '2024-07-10T06:50:07.1918005+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918005+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.5955,
                  engineLifeLimitedPartMaintenanceProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'feb27cee-7512-4c33-a070-f79752379efd',
                    limitId: '1879df5d-eecf-4e82-8466-1788ca6c8c22',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'feb27cee-7512-4c33-a070-f79752379efd',
                  componentCategory: 5,
                  componentTypeId: '50022aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '8b860e08-238f-4703-adf4-651fc7aa7118',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 251910,
                },
                createdAt: '2024-07-10T06:50:07.1918075+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918075+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 2.7165,
                  engineLifeLimitedPartMaintenanceProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                    limitId: '8363f53f-74e5-4f09-82c1-565d49d887b5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '53a68923-bb19-4dee-9efc-b2418ffd8432',
                  componentCategory: 5,
                  componentTypeId: '50023aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '43798352-01e0-4b43-9fc9-7253e7f35acd',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 54330,
                },
                createdAt: '2024-07-10T06:50:07.1918081+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918081+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 8.612,
                  engineLifeLimitedPartMaintenanceProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                    limitId: '5a395c1b-6cb3-463a-8cf8-863129e6d6d2',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b0d6aa06-45c3-4397-8ad9-b5d9907c45b4',
                  componentCategory: 5,
                  componentTypeId: '50020aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '246b1b6f-59f7-4d75-a17e-75478a2ff446',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 172240,
                },
                createdAt: '2024-07-10T06:50:07.1918065+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918066+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 25.6435,
                  engineLifeLimitedPartMaintenanceProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                    limitId: '03beff59-9c3f-4bfb-affc-e1346dff7967',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'a1b93757-eea9-420e-b84e-1b0be3cdd1b8',
                  componentCategory: 5,
                  componentTypeId: '50003aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '803bb296-3653-44f5-b268-7578b701bf34',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 512870,
                },
                createdAt: '2024-07-10T06:50:07.1917979+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917979+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 7.827,
                  engineLifeLimitedPartMaintenanceProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                    limitId: 'a7208d30-55f9-4f04-8420-1f140b566192',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '50d273b2-4ffe-451e-89a5-00279800c1aa',
                  componentCategory: 5,
                  componentTypeId: '50016aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '3a53d0ec-3d44-429e-aad6-77b674e10fd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 156540,
                },
                createdAt: '2024-07-10T06:50:07.1918045+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918045+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.591,
                  engineLifeLimitedPartMaintenanceProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                    limitId: 'b07243e8-7171-4d76-9416-da669aa1a05f',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '12a5b217-b064-43c6-8e25-4341ff2c1375',
                  componentCategory: 5,
                  componentTypeId: '50017aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'a9adfd09-a6f8-42e4-9d4e-7ae1093e3dd4',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211820,
                },
                createdAt: '2024-07-10T06:50:07.1918049+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918049+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.3495,
                  engineLifeLimitedPartMaintenanceProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                    limitId: '4338565a-e32c-411f-8c98-c0b170ea613b',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '6eeec15b-145e-4096-8b97-8c9a8b629243',
                  componentCategory: 5,
                  componentTypeId: '50019aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '98dae005-7bbb-4e36-86d4-7e4e80c547b1',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 206990,
                },
                createdAt: '2024-07-10T06:50:07.1918061+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918061+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 5.4185,
                  engineLifeLimitedPartMaintenanceProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                    limitId: 'cbb9bcbf-85f2-4534-91d2-add412649e4d',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '994fc6a8-813f-4f3f-8e63-b26fb42b4243',
                  componentCategory: 5,
                  componentTypeId: '50021aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'ea7c6ae0-1172-4f22-8ba2-9b1142d4074a',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 108370,
                },
                createdAt: '2024-07-10T06:50:07.191807+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191807+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.2495,
                  engineLifeLimitedPartMaintenanceProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                    limitId: 'f2aa3a4c-3010-4b6d-94ee-24688d0810fa',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'b641aa12-b048-4786-8539-f81daba4fda5',
                  componentCategory: 5,
                  componentTypeId: '50025aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '883dcfb0-28cb-415e-9e88-9c3fddfb1345',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 184990,
                },
                createdAt: '2024-07-10T06:50:07.191809+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191809+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 15.282,
                  engineLifeLimitedPartMaintenanceProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                    limitId: 'c4c07017-0404-4667-a53c-53d3e80d75e8',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '820dff67-af61-4ffb-a3a1-5415979cacfa',
                  componentCategory: 5,
                  componentTypeId: '50001aaa-ad67-4c77-b8ac-073b8a91db2f',
                  kbProgramId: '7a9d5d9f-11b5-4973-a957-bc62a1991c77',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 305640,
                },
                createdAt: '2024-07-10T06:50:07.1916418+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1916419+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 10.5635,
                  engineLifeLimitedPartMaintenanceProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                    limitId: 'a0b8acb2-70d3-45d8-bdcf-9f20286c6b56',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'c809a057-c641-45a6-b382-e4a65a8d2db3',
                  componentCategory: 5,
                  componentTypeId: '50010aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '53d4d125-0174-4c98-94b1-bd9ed55e9ece',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 211270,
                },
                createdAt: '2024-07-10T06:50:07.1918015+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918015+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 12.3365,
                  engineLifeLimitedPartMaintenanceProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                    limitId: '45703866-8859-42c3-b8c6-537ad9ec3fb5',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '492a05e0-27ef-4934-a8ae-9b6183ec9c8b',
                  componentCategory: 5,
                  componentTypeId: '50007aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '29bc4407-a9b8-42c2-96f7-cef651fa8a0e',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 246730,
                },
                createdAt: '2024-07-10T06:50:07.1918+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 4.322,
                  engineLifeLimitedPartMaintenanceProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                    limitId: '706f33a0-8675-48cb-b915-da34cad592c6',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '024e1857-f6bf-4b46-a38e-8ef315dba085',
                  componentCategory: 5,
                  componentTypeId: '50009aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'f5de7c29-b4c6-403d-8a72-d3b2304d391f',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 86440,
                },
                createdAt: '2024-07-10T06:50:07.191801+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191801+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 9.4235,
                  engineLifeLimitedPartMaintenanceProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                    limitId: '9d932e1a-250e-49b1-9993-97e55ec65b2a',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: 'cd574fee-1ad8-4e78-a77e-c000872cfbe8',
                  componentCategory: 5,
                  componentTypeId: '50018aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: 'd1cd9afd-6549-4e8a-885c-d92c6275209c',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 188470,
                },
                createdAt: '2024-07-10T06:50:07.1918054+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1918054+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 3.302,
                  engineLifeLimitedPartMaintenanceProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                    limitId: '08591d23-9514-4b01-ad55-b2102320387c',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '8f4e3a30-edc2-40ba-b05a-16984b56c086',
                  componentCategory: 5,
                  componentTypeId: '50006aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '0d1c32bd-20ff-4e9a-b7d7-dc01d9089870',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 66040,
                },
                createdAt: '2024-07-10T06:50:07.1917995+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.1917995+00:00',
              },
              {
                engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                check: {
                  costPerCycle: 35.4135,
                  engineLifeLimitedPartMaintenanceProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  lifeLimitedPartCheckLimit: {
                    lifeLimitedPartCheckId: '570cc030-7666-4324-ba24-532c35386bba',
                    limitId: '9d1fd3d6-0493-4dab-b28c-968e3e969fe3',
                    utilizationUnit: 3,
                    utilization: 20000,
                  },
                  checkId: '570cc030-7666-4324-ba24-532c35386bba',
                  componentCategory: 5,
                  componentTypeId: '50005aaa-3d67-5c77-a9ac-073b8a91db2f',
                  kbProgramId: '5e8110d9-6274-4c77-a8f4-ff208d50bf85',
                  kbReferenceYear: 2024,
                  checkType: 2,
                  utilizationUnit: 3,
                  baseCost: 708270,
                },
                createdAt: '2024-07-10T06:50:07.191799+00:00',
                lastModifiedAt: '2024-07-10T06:50:07.191799+00:00',
              },
            ],
            totalLLPsCost: 5834020,
            totalLLPsCostPerCycle: 291.701,
            environmentalImpacts: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 4,
                percentage: -35,
                id: '04cb41aa-0c3f-40a6-bd2a-0373db8dd8d3',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 3,
                percentage: -30,
                id: '867c8353-3ec2-4cac-b5e5-09fa67d1d235',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 2,
                percentage: -20,
                id: 'b1b29aa8-2007-481c-a98b-176947c0b129',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                environmentalImpactType: 1,
                percentage: 0,
                id: '4e4f9869-9cb2-4446-942d-b8b1d8775535',
              },
            ],
            derateRatios: [
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 0,
                intervalEnd: 5,
                percentage: 0,
                id: 'af9af015-3661-4eb4-a69e-42f1da71d207',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 10,
                intervalEnd: 15,
                percentage: 15,
                id: '30bae0cd-e735-4f52-a33f-9d13ac391fbe',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 5,
                intervalEnd: 10,
                percentage: 8,
                id: '30fdb335-6f74-4627-8d01-a1719e9ce8c9',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 15,
                intervalEnd: 20,
                percentage: 22,
                id: '1b648310-587f-426d-9e13-d201378a2550',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 25,
                intervalEnd: 100,
                percentage: 33,
                id: '0c421d2d-59da-4d73-bff4-dbd35a308508',
              },
              {
                engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                intervalStart: 20,
                intervalEnd: 25,
                percentage: 28,
                id: '37b05db3-554c-4a3f-9b69-ddc80ea94894',
              },
            ],
            stubLife: {
              engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              intervalStart: 0,
              intervalEnd: 15,
              id: '20c99bc7-1a3e-423c-b29f-4b0f15fdf60b',
            },
            maintenanceProgramName: 'IAE - V2527-A5 - 2024',
            isActive: true,
            dateOfCreation: '2024-07-10T06:50:07.1082445Z',
            kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            componentCategory: 2,

            compatibleComponentModels: ['V2527-A5'],
            kbReferenceYear: 2024,
            originalEquipmentManufacturer: 'IAE',
            inflation: [
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 5,
                referenceYear: 2024,
                percentage: 5,
                id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
              },
              {
                maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
                componentType: 4,
                referenceYear: 2023,
                percentage: 4,
                id: '17c43787-b690-4a2c-8060-d3878abd033f',
              },
            ],
            basicAssumptions: {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              checkDowntimeMonths: 2,
              monthsDelayInPaymentOnClaims: 1,
              id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
            },
            createdAt: '2024-07-10T06:50:07.1082588+00:00',
            lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
          },
          engineMaintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          flightHoursFlightCyclesRatioIdentifier: '1.5:1',
          firstRunLimit: 30000,
          matureLimit: 27500,
          id: '49608ff2-a7de-49eb-8e38-e14008e0d31f',
        },
      ],
      componentTypeInfo: {
        componentCategory: 2,
        createdAt: '2024-07-10T06:49:46.5788857+00:00',
        lastModifiedAt: '2024-07-10T06:49:46.5788861+00:00',
      },
      inflation: {
        maintenanceProgram: {
          maintenanceProgramName: 'IAE - V2527-A5 - 2024',
          isActive: true,
          dateOfCreation: '2024-07-10T06:50:07.1082445Z',
          kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          componentCategory: 2,

          compatibleComponentModels: ['V2527-A5'],
          kbReferenceYear: 2024,
          originalEquipmentManufacturer: 'IAE',
          inflation: [
            null,
            {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentType: 4,
              referenceYear: 2023,
              percentage: 4,
              id: '17c43787-b690-4a2c-8060-d3878abd033f',
            },
          ],
          basicAssumptions: {
            maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
            checkDowntimeMonths: 2,
            monthsDelayInPaymentOnClaims: 1,
            id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
          },
          createdAt: '2024-07-10T06:50:07.1082588+00:00',
          lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
        },
        maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
        componentType: 5,
        referenceYear: 2024,
        percentage: 5,
        id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
      },
      maintenanceProgramName: 'IAE - V2527-A5 - 2024',
      isActive: true,
      dateOfCreation: '2024-07-10T06:50:07.1082445Z',
      kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
      componentCategory: 2,

      compatibleComponentModels: ['V2527-A5'],
      kbReferenceYear: 2024,
      originalEquipmentManufacturer: 'IAE',
      basicAssumptions: {
        maintenanceProgram: {
          maintenanceProgramName: 'IAE - V2527-A5 - 2024',
          isActive: true,
          dateOfCreation: '2024-07-10T06:50:07.1082445Z',
          kbProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
          componentCategory: 2,

          compatibleComponentModels: ['V2527-A5'],
          kbReferenceYear: 2024,
          originalEquipmentManufacturer: 'IAE',
          inflation: [
            {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentType: 5,
              referenceYear: 2024,
              percentage: 5,
              id: '275a7ac6-fdf6-4458-b109-c7a41a32d68f',
            },
            {
              maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
              componentType: 4,
              referenceYear: 2023,
              percentage: 4,
              id: '17c43787-b690-4a2c-8060-d3878abd033f',
            },
          ],
          createdAt: '2024-07-10T06:50:07.1082588+00:00',
          lastModifiedAt: '2024-07-10T06:50:07.1082589+00:00',
        },
        maintenanceProgramId: 'bdc38faa-80c8-4efe-a6aa-6e76f7b060cc',
        checkDowntimeMonths: 2,
        monthsDelayInPaymentOnClaims: 1,
        id: '613215ce-251a-4a67-8df8-feb8cc3154a8',
      },
      createdAt: '2024-07-16T10:48:43.9977025+00:00',
      lastModifiedAt: '2024-07-16T10:48:43.9977025+00:00',
    } as unknown as IGetEngineMaintenanceProgramDetailsQueryResponse)
  }),
]

export { engineMaintenanceProgramsApiMocks }
