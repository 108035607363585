import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetAircraftDetailsQueryResponse } from './types'
import { type AssetContract } from '../../../models'

const aircraftsApiMocks = [
  http.get(APIRoutes.AssetsModule.AircraftsController.GetDetails('*'), () => {
    return HttpResponse.json<IGetAircraftDetailsQueryResponse>(
      {
        assetId: 'bcb4ba13-90b5-4b38-a2c1-8adbdd8171f2',
        aircraftDetails: {
          registrationNo: 'EC-NBB',
          installedEngineSerialNumbers: ['V1234', 'V5678'],
          engineModel: 'V2527-A5',
          engineThrustRating: 27000,
          manufacturingDetail: {
            aircraftId: 'bcb4ba13-90b5-4b38-a2c1-8adbdd8171f2',
            manufacturer: 'Airbus',
            dom: '2011-03-22',
          },
          assetId: 'bcb4ba13-90b5-4b38-a2c1-8adbdd8171f2',
          assetType: 1,
          aircraftType: 'A320-200',
          serialNumber: '1111',
          portfolio: 'Peregrine',
          region: 'EMEA',
          dateOfLastTechSpec: new Date('2023-03-01'),
          contract: {
            assetId: 'bcb4ba13-90b5-4b38-a2c1-8adbdd8171f2',
            leaseStartDate: '2021-06-11',
            leaseEndDate: '2028-06-10',
            lessee: 'Air FlyTest',
            lesseeHabitualBase: 'Spain',
            isMaintenanceReservePayer: true,
            operatingEnvironment: 1,
            calculatedHoursPerCycleRatio: 1.8,
            enginesContracts: [],
            id: 'b7964078-9102-4e8f-a61b-ebffd7e25e9d',
          } as unknown as AssetContract,
          components: [],
        },
      },
      { status: 200 },
    )
  }),
]

export { aircraftsApiMocks }
