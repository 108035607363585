import React, { useEffect } from 'react'
import { PageSizeSelector } from './PageSizeSelector'
import { PaginationNavigation } from './PaginationNavigation'
import { type PageSizes } from '../../../platform/models'

interface IPaginationProps {
  entityLabel: string
  currentPage: number
  totalPages: number
  pageSize: PageSizes
  isLoading: boolean
  totalCount: number
  setCurrentPage: (page: number) => void
  setPageSize: (size: PageSizes) => void
}

const Pagination: React.FC<IPaginationProps> = ({
  entityLabel,
  currentPage,
  totalPages,
  pageSize,
  isLoading,
  totalCount,
  setCurrentPage,
  setPageSize,
}) => {
  useEffect(() => {
    // reset current page to 0 when page size changes
    if (currentPage !== 0) {
      setCurrentPage(0)
    }
  }, [currentPage, pageSize, setCurrentPage])

  return (
    <div className="flex items-center justify-between p-4" data-testid="pagination">
      <p className="text-sm">
        Total {entityLabel}: {totalCount}
      </p>
      <div className="flex items-center">
        <PaginationNavigation
          currentPage={currentPage}
          totalPages={totalPages}
          pageSize={pageSize}
          isLoading={isLoading}
          totalCount={totalCount}
          setCurrentPage={setCurrentPage}
        />
        <PageSizeSelector pageSize={pageSize} setPageSize={setPageSize} setCurrentPage={setCurrentPage} />
      </div>
    </div>
  )
}

export { Pagination }
