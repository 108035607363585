import { useMemo } from 'react'
import { useReactTable, getCoreRowModel, getExpandedRowModel, type ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import { type AssetSearchDTO } from '../../models'
import {
  AssetType,
  CellWithExpander,
  ExpandableRow,
  getAssetAge,
  cn,
  getAssetTypeDisplayName,
  longDateFormat,
  isArrayEmptyOrNull,
  TextCellWithLabel,
} from '@flyward/platform'
import { type NavigateFunction, useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { AssetSelectionCell } from './SelectionCell'
import { AircraftExpandedDataGridWrapper } from './AircraftExpandedDataGridWrapper'
import { StandaloneEngineExpandedDataGridWrapper } from './StandaloneEngineExpandedDataGridWrapper'

const getFormattedValue = (getValue: () => unknown) => {
  const value = getValue()
  return value as string
}

const getFormattedDate = (getValue: () => unknown) => {
  const value = getFormattedValue(getValue)
  if (value === undefined) {
    return ''
  }
  const date = new Date(value)
  return format(date, longDateFormat)
}

const getAge = (getValue: () => unknown) => {
  const value = getFormattedValue(getValue)
  return getAssetAge(value)
}

const generateColumns = (
  navigate: NavigateFunction,
  selectedAssetIds: string[],
  setSelectedAssetsIds: (value: string[]) => void,
): Array<ColumnDef<AssetSearchDTO>> => [
  {
    id: 'selection',
    accessorKey: 'serialNumber',
    header: () => null,
    cell: ({ getValue, row }) => (
      <AssetSelectionCell
        serialNumber={getFormattedValue(getValue)}
        assetType={row.original.assetType}
        isExpanded={row.getIsExpanded()}
        selectedAssetIds={selectedAssetIds}
        setSelectedAssetsIds={setSelectedAssetsIds}
        onClick={() => {
          if (row.original.assetType === AssetType.Aircraft) {
            navigate(`aircrafts/${row.original.assetId}`)
          } else {
            navigate(`engines/${row.original.assetId}`)
          }
        }}
      />
    ),
  },
  {
    accessorKey: 'lessee',
    header: () => null,
    cell: ({ getValue, row }) => (
      <TextCellWithLabel
        label="Lessee"
        className="w-42"
        info={getFormattedValue(getValue)}
        labelClassName={`${row.getIsExpanded() && 'text-black-20'}`}
        infoClassName={`${row.getIsExpanded() && 'text-text-4'}`}
      />
    ),
  },
  {
    accessorKey: 'region',
    header: () => null,
    cell: ({ getValue, row }) => (
      <TextCellWithLabel
        label="Region"
        className="w-36"
        info={getFormattedValue(getValue)}
        labelClassName={`${row.getIsExpanded() && 'text-black-20'}`}
        infoClassName={`${row.getIsExpanded() && 'text-text-4'}`}
      />
    ),
  },
  {
    accessorKey: 'aircraftModel',
    header: () => null,
    cell: ({ getValue, row }) => {
      const value = getFormattedValue(getValue)
      return (
        <TextCellWithLabel
          label={'Aircraft Model'}
          info={value}
          labelClassName={cn(row.getIsExpanded() && 'text-black-20', isEmpty(value) && 'select-none text-transparent	')}
          infoClassName={`${row.getIsExpanded() && 'text-text-4'}`}
        />
      )
    },
  },
  {
    accessorKey: 'engineModel',
    header: () => null,
    cell: ({ getValue, row }) => (
      <TextCellWithLabel
        label="Engine Model"
        info={getFormattedValue(getValue)}
        labelClassName={`${row.getIsExpanded() && 'text-black-20'}`}
        infoClassName={`${row.getIsExpanded() && 'text-text-4'}`}
      />
    ),
  },
  {
    id: 'assetAge',
    accessorKey: 'dom',
    header: () => null,
    cell: ({ getValue, row }) => (
      <TextCellWithLabel
        label={`${getAssetTypeDisplayName(row.original.assetType)} Age`}
        info={getAge(getValue)}
        labelClassName={`${row.getIsExpanded() && 'text-black-20'}`}
        infoClassName={`${row.getIsExpanded() && 'text-text-4'}`}
      />
    ),
  },
  {
    accessorKey: 'redeliveryDate',
    header: () => null,
    cell: ({ getValue, row }) => (
      <TextCellWithLabel
        label="Redelivery Date"
        info={getFormattedDate(getValue)}
        labelClassName={`${row.getIsExpanded() && 'text-black-20'}`}
        infoClassName={`${row.getIsExpanded() && 'text-text-4'}`}
      />
    ),
  },
  {
    id: 'expander',
    header: () => null,
    cell: ({ row }) => <CellWithExpander<AssetSearchDTO> row={row} />,
  },
]

const getExpandedComponent = (assetType: AssetType, assetId: string) => {
  if (isEmpty(assetId)) return undefined
  return assetType === AssetType.Aircraft ? AircraftExpandedDataGridWrapper : StandaloneEngineExpandedDataGridWrapper
}

interface IAssetsDataGridProps {
  data: AssetSearchDTO[]
  selectedAssetIds: string[]
  setSelectedAssetsIds: (value: string[]) => void
}

export const AssetsDataGrid = ({ data, selectedAssetIds, setSelectedAssetsIds }: IAssetsDataGridProps) => {
  const navigate = useNavigate()
  const columns = useMemo(() => generateColumns(navigate, selectedAssetIds, setSelectedAssetsIds), [navigate, selectedAssetIds, setSelectedAssetsIds])

  const table = useReactTable<AssetSearchDTO>({
    data,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableMultiRowSelection: false,
  })

  return isArrayEmptyOrNull(data) ? (
    <div className="flex h-[calc(100vh-14rem)] w-full items-center justify-center">
      <p className="mt-6 text-sm text-text-1">No assets found</p>
    </div>
  ) : (
    <div className="block h-[calc(100vh-14rem)] w-full overflow-x-auto">
      <table className="w-full">
        <tbody className="w-full">
          {table.getRowModel().rows.map((row) => (
            <ExpandableRow
              key={row.original.assetId}
              row={row}
              expandedComponent={getExpandedComponent(row.original.assetType, row.original.assetId)}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default AssetsDataGrid
