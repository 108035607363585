import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetAllAssetsBySearchQueryResponse } from './types'

const assetsApiMocks = [
  http.get(APIRoutes.AssetsModule.AssetsController.GetAllAssets(), () => {
    return HttpResponse.json<IGetAllAssetsBySearchQueryResponse>(
      {
        items: [
          {
            assetId: 'bcb4ba13-90b5-4b38-a2c1-8adbdd8171f2',
            assetType: 1,
            serialNumber: '1111',
            type: 'A320-200',
            aircraftModel: 'A320-200',
            engineModel: 'V2527-A5',
            lessee: 'Air FlyTest',
            region: 'EMEA',
            operatingEnvironment: 1,
            calculatedHoursPerCycleRatio: 1.8,
            dom: '2011-03-22',
            redeliveryDate: '2028-06-10',
            dateOfLastTechSpec: '2023-03-01',
            portfolio: 'Peregrine',
          },
          {
            assetId: '0f81d63e-9fee-4cc3-8d88-2df1a1890906',
            assetType: 1,
            serialNumber: '4637',
            type: 'B737-800',
            aircraftModel: 'B737-800',
            engineModel: 'V2527-A5',
            lessee: 'Air Aviation Network SAS',
            region: 'EMEA',
            operatingEnvironment: 1,
            calculatedHoursPerCycleRatio: 1.8,
            dom: '2011-03-22',
            redeliveryDate: '2029-06-10',
            dateOfLastTechSpec: '2023-03-01',
            portfolio: 'Peregrine',
          },
          {
            assetId: 'ea03eda5-336a-4838-9065-974a90ae885b',
            assetType: 1,
            serialNumber: 'E2E_4637-le_2039-E2_new',
            type: 'B737-800',
            aircraftModel: 'B737-800',
            engineModel: 'V2527-A5',
            lessee: 'Air Aviation Network SAS',
            region: 'EMEA',
            operatingEnvironment: 1,
            calculatedHoursPerCycleRatio: 1.8,
            dom: '2011-03-22',
            redeliveryDate: '2039-10-31',
            dateOfLastTechSpec: '2023-03-01',
            portfolio: 'Peregrine',
          },
          {
            assetId: 'd11d7a91-a7e7-43fa-85d3-c594b24e294e',
            assetType: 1,
            serialNumber: 'E2E-4637-one_llp-E2_new',
            type: 'B737-800',
            aircraftModel: 'B737-800',
            engineModel: 'V2527-A5',
            lessee: 'Air Aviation Network SAS',
            region: 'EMEA',
            operatingEnvironment: 1,
            calculatedHoursPerCycleRatio: 1.8,
            dom: '2011-03-22',
            redeliveryDate: '2039-10-31',
            dateOfLastTechSpec: '2023-03-01',
            portfolio: 'Peregrine',
          },
          {
            assetId: 'abf6d5bb-62a3-428f-96a3-823542fdc704',
            assetType: 1,
            serialNumber: 't4637-dom_2012',
            type: 'B737-800',
            aircraftModel: 'B737-800',
            engineModel: 'V2527-A5',
            lessee: 'Air Aviation Network SAS',
            region: 'EMEA',
            operatingEnvironment: 1,
            calculatedHoursPerCycleRatio: 1.8,
            dom: '2010-03-22',
            redeliveryDate: '2039-06-10',
            dateOfLastTechSpec: '2023-03-01',
            portfolio: 'Peregrine',
          },
          {
            assetId: '41d33f84-ebe8-4261-b603-a41602ba7e82',
            assetType: 1,
            serialNumber: 't4637-le_2049-dom_2010',
            type: 'B737-800',
            aircraftModel: 'B737-800',
            engineModel: 'V2527-A5',
            lessee: 'Air Aviation Network SAS',
            region: 'EMEA',
            operatingEnvironment: 1,
            calculatedHoursPerCycleRatio: 1.8,
            dom: '2009-03-22',
            redeliveryDate: '2049-06-10',
            dateOfLastTechSpec: '2023-03-01',
            portfolio: 'Peregrine',
          },
          {
            assetId: '4c423492-0eb1-442e-a92d-59f7580d0a75',
            assetType: 1,
            serialNumber: 't4637-le_2059-dom_2005',
            type: 'B737-800',
            aircraftModel: 'B737-800',
            engineModel: 'V2527-A5',
            lessee: 'Air Aviation Network SAS',
            region: 'EMEA',
            operatingEnvironment: 1,
            calculatedHoursPerCycleRatio: 1.8,
            dom: '2004-03-22',
            redeliveryDate: '2059-06-10',
            dateOfLastTechSpec: '2023-03-01',
            portfolio: 'Peregrine',
          },
          {
            assetId: '0a327116-e839-4914-86b4-97c99105e82b',
            assetType: 1,
            serialNumber: 't4637-le_2069-dom_2002',
            type: 'B737-800',
            aircraftModel: 'B737-800',
            engineModel: 'V2527-A5',
            lessee: 'Air Aviation Network SAS',
            region: 'EMEA-TEST',
            operatingEnvironment: 1,
            calculatedHoursPerCycleRatio: 1.8,
            dom: '2001-03-22',
            redeliveryDate: '2059-06-10',
            dateOfLastTechSpec: '2023-03-01',
            portfolio: 'Peregrine-TEST',
          },
          {
            assetId: '9cfa4c1c-cfd0-4c21-a82c-e155f00fdb7d',
            assetType: 2,
            serialNumber: 'V15768',
            type: 'B737-800',
            aircraftModel: '',
            engineModel: 'V2527-A5',
            lessee: 'Air Aviation Network SAS',
            region: 'EMEA',
            operatingEnvironment: 1,
            calculatedHoursPerCycleRatio: 1.8,
            dom: '2011-10-01',
            redeliveryDate: '2039-10-31',
            dateOfLastTechSpec: '2023-03-01',
            portfolio: 'Peregrine',
          },
          {
            assetId: '9fb063f9-3c61-45cd-9d6b-f52668b012f6',
            assetType: 2,
            serialNumber: 'V15770-one_llp-E_new',
            type: 'B737-800',
            aircraftModel: '',
            engineModel: 'V2527-A5',
            lessee: 'Air Aviation Network SAS',
            region: 'EMEA',
            operatingEnvironment: 1,
            calculatedHoursPerCycleRatio: 1.8,
            dom: '2023-03-01',
            redeliveryDate: '2039-10-31',
            dateOfLastTechSpec: '2023-03-01',
            portfolio: 'Peregrine',
          },
          {
            assetId: '22e8537c-1ef9-45bb-af56-028bd2a40e74',
            assetType: 2,
            serialNumber: 'Vt15768-one_llp',
            type: 'B737-800',
            aircraftModel: '',
            engineModel: 'V2527-A5',
            lessee: 'Air Aviation Network SAS',
            region: 'EMEA',
            operatingEnvironment: 1,
            calculatedHoursPerCycleRatio: 1.8,
            dom: '2011-10-01',
            redeliveryDate: '2039-10-31',
            dateOfLastTechSpec: '2023-03-01',
            portfolio: 'Peregrine',
          },
        ],
        currentPage: 1,
        pageSize: 10,
        totalCount: 11,
      },
      { status: 200 },
    )
  }),
]

export { assetsApiMocks }
