import {
  AssetFilterDialog,
  type AssetSearchDTO,
  AssetTypeFilter,
  AssetsDataGrid,
  type IFilterData,
  filterAssets,
  initialFilterData,
  initializeFilterData,
  updateFilterSelections,
} from '@flyward/assets'
import {
  type AssetType,
  Button,
  ButtonVariant,
  ComponentContainer,
  ErrorBoundary,
  type IButtonProps,
  IconVariant,
  SearchInput,
  Size,
  isArrayEmptyOrNull,
  useHeaderContent,
} from '@flyward/platform'
import { useCallback, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FleetEmptyState } from '../../assets'
import { PageContainer } from '../../layout'
import { useGetAllAssetsBySearchQuery } from '@flyward/platform/store/slices'
import queryString from 'query-string'

enum ActionType {
  Initialize = 'INITIALIZE_DATA',
  Search = 'SEARCH_TERM',
  ResetSearch = 'RESET_SEARCH_TERM',
  Filter = 'FILTER_DATA',
  ResetFilter = 'RESET_FILTER_DATA',
}

interface IInitializeDataAction {
  type: ActionType.Initialize
  payload: AssetSearchDTO[]
}

interface ISearchAction {
  type: ActionType.Search | ActionType.ResetSearch
  payload: string
}

interface IFilterAction {
  type: ActionType.Filter | ActionType.ResetFilter
  payload: IFilterData
}

type Action = IInitializeDataAction | IFilterAction | ISearchAction

interface IAssetsState {
  assets: AssetSearchDTO[]
  filterData: IFilterData
}

const emptyState: IAssetsState = {
  assets: [],
  filterData: initialFilterData,
}

export const FleetPage = () => {
  const navigate = useNavigate()
  const { setTitle, setButtons } = useHeaderContent()
  const [initialState, setInitialState] = useState<IAssetsState>(emptyState)
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([])
  const [assetTypeFilter, setAssetTypeFilter] = useState<AssetType | undefined>(undefined)
  const [searchTerm, setSearchTerm] = useState<string>('')

  useEffect(() => {
    const buttonProps: IButtonProps[] = [
      {
        variant: ButtonVariant.Primary,
        size: Size.Medium,
        label: 'Fly Forward',
        leftIcon: IconVariant.FlightTakeOff,
        onClick: () => {
          const assetIds: string[] = []
          selectedAssetIds.forEach((assetSerialNumber) => {
            const asset = initialState.assets.find((t) => t.serialNumber === assetSerialNumber)
            const assetId = asset?.assetId
            if (assetId !== undefined) {
              assetIds.push(assetId)
            }
          })
          navigate(`/flyforward/?${queryString.stringify({ assetIds })}`)
        },
      },
    ]

    setTitle('Fleet Summary')
    setButtons(buttonProps)

    return () => {
      setTitle('')
      setButtons([])
    }
  }, [initialState.assets, navigate, selectedAssetIds, setButtons, setTitle])

  const reducer = useCallback(
    (state: IAssetsState, action: Action) => {
      switch (action.type) {
        case ActionType.Initialize: {
          const assets: AssetSearchDTO[] = action.payload
          const filterData = initializeFilterData(assets)
          setInitialState((prev) => ({ ...prev, assets, filterData }))

          return { ...state, assets, filterData }
        }
        case ActionType.Search: {
          const searchTerm = action.payload

          setSearchTerm(searchTerm)
          const assets = filterAssets(initialState.assets, state.filterData)

          let updatedFilters = initializeFilterData(assets)
          updatedFilters = updateFilterSelections(updatedFilters, state.filterData)

          return { ...state, searchTerm, assets, filterData: updatedFilters }
        }
        case ActionType.ResetSearch: {
          setSearchTerm('')

          const assets = filterAssets(initialState.assets, state.filterData)

          let updatedFilters = initializeFilterData(assets)
          updatedFilters = updateFilterSelections(updatedFilters, state.filterData)

          return { ...state, searchTerm: '', assets, filterData: updatedFilters }
        }
        case ActionType.Filter: {
          const filterData = action.payload
          const assets = filterAssets(initialState.assets, filterData)
          let updatedFilters = initializeFilterData(assets)
          updatedFilters = updateFilterSelections(updatedFilters, filterData)

          return { ...state, assets, filterData: updatedFilters }
        }
        case ActionType.ResetFilter: {
          const assets = initialState.assets

          let updatedFilters = initializeFilterData(assets)
          updatedFilters = updateFilterSelections(updatedFilters, action.payload)

          return { ...state, assets, filterData: updatedFilters }
        }
        default:
          return state
      }
    },
    [initialState.assets],
  )

  const [assetsState, dispatch] = useReducer(reducer, emptyState)

  const { data: allAssets } = useGetAllAssetsBySearchQuery({ searchTerm, expand: true, assetType: assetTypeFilter })

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: ActionType.Initialize, payload: allAssets?.items ?? [] })
    }

    fetchData()
  }, [initialState.assets.length, allAssets, assetTypeFilter])

  const searchAssets = useCallback((value: string) => {
    dispatch({ type: ActionType.Search, payload: value })
  }, [])

  const resetSearchAssets = useCallback(() => {
    dispatch({ type: ActionType.ResetSearch, payload: '' })
  }, [])

  const applyFilter = useCallback((filterData: IFilterData) => {
    dispatch({ type: ActionType.Filter, payload: filterData })
  }, [])

  const deleteFilters = useCallback(() => {
    dispatch({ type: ActionType.ResetFilter, payload: initialState.filterData })
  }, [initialState.filterData])

  const isFilterApplied =
    assetsState.filterData.selectedMsn.length !== assetsState.filterData.msns.length ||
    assetsState.filterData.selectedLessee.length !== assetsState.filterData.lessees.length ||
    assetsState.filterData.selectedAircraftModel.length !== assetsState.filterData.aircraftModels.length ||
    assetsState.filterData.selectedEngineType.length !== assetsState.filterData.engineTypes.length ||
    assetsState.filterData.selectedAircraftAge.length !== assetsState.filterData.aircraftAges.length ||
    assetsState.filterData.selectedRedeliveryDate.length !== assetsState.filterData.redeliveryDates.length ||
    assetsState.filterData.selectedRegions.length !== assetsState.filterData.regions.length ||
    assetsState.filterData.selectedPortfolio.length !== assetsState.filterData.portfolio.length

  const isFilteredDataEmpty = isArrayEmptyOrNull(assetsState.assets) && searchTerm.length === 0
  return (
    <PageContainer>
      <ErrorBoundary>
        <div className="flex flex-grow flex-col gap-y-2">
          <ComponentContainer className="flex-grow justify-start gap-0">
            <div className="flex w-full items-center justify-between bg-header-table p-6">
              <div className="flex gap-x-6">
                <SearchInput inputClassName="w-49" setValueAfterValidation={searchAssets} resetValue={resetSearchAssets} />
                <AssetTypeFilter
                  assetType={assetTypeFilter}
                  setAssetType={(assetType) => {
                    setAssetTypeFilter(assetType)
                  }}
                />
                <AssetFilterDialog
                  filterData={assetsState.filterData}
                  applyFilter={applyFilter}
                  availableFilterOptions={{
                    msns: initialState.filterData.msns,
                    lessees: initialState.filterData.lessees,
                    aircraftModels: initialState.filterData.aircraftModels,
                    engineTypes: initialState.filterData.engineTypes,
                    aircraftAges: initialState.filterData.aircraftAges,
                    redeliveryDates: initialState.filterData.redeliveryDates,
                    regions: initialState.filterData.regions,
                    portfolio: initialState.filterData.portfolio,
                  }}
                />
                {isFilterApplied && (
                  <Button variant={ButtonVariant.Ghost} label="Delete all Filters" onClick={deleteFilters} className="text-xs font-semibold" />
                )}
              </div>
              <Button variant={ButtonVariant.Secondary} size={Size.Small} label="New Aircraft/Engine" onClick={() => {}} leftIcon={IconVariant.Add} />
            </div>
            {isFilteredDataEmpty ? (
              <FleetEmptyState width={998} height={580} />
            ) : (
              <AssetsDataGrid
                key={assetTypeFilter}
                data={assetsState.assets}
                selectedAssetIds={selectedAssetIds}
                setSelectedAssetsIds={setSelectedAssetIds}
              />
            )}
          </ComponentContainer>
          <p className="ml-6 !text-xs text-text-2">Total Assets: {assetsState.assets.length}</p>
        </div>
      </ErrorBoundary>
    </PageContainer>
  )
}
