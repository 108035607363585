export enum ComponentCategory {
  Airframe = 1,
  EngineUnit = 2,
  AuxiliaryPowerUnit = 3,
  EnginePerformanceRestoration = 4,
  LifeLimitedPart = 5,
  LandingGear = 6,
  ThrustReversers = 7,
}

export const ComponentCategoryDisplay = (value: ComponentCategory) => {
  switch (value) {
    case ComponentCategory.Airframe:
      return 'Airframe'
    case ComponentCategory.EngineUnit:
      return 'Engine Unit'
    case ComponentCategory.AuxiliaryPowerUnit:
      return 'APU'
    case ComponentCategory.EnginePerformanceRestoration:
      return 'EPR'
    case ComponentCategory.LifeLimitedPart:
      return 'LLP'
    case ComponentCategory.LandingGear:
      return 'Landing Gear'
    case ComponentCategory.ThrustReversers:
      return 'Thrust Reversers'
  }
}

export const ComponentTypeURI = (value: ComponentCategory) => {
  switch (value) {
    case ComponentCategory.Airframe:
      return 'airframes'
    case ComponentCategory.EngineUnit:
      return 'engines'
    case ComponentCategory.AuxiliaryPowerUnit:
      return 'auxiliary-power-units'
    case ComponentCategory.EnginePerformanceRestoration:
      return 'engine-performance-restorations'
    case ComponentCategory.LifeLimitedPart:
      return 'engine-life-limited-parts'
    case ComponentCategory.LandingGear:
      return 'landing-gears'
    case ComponentCategory.ThrustReversers:
      return 'thrust-reversers'
  }
}
