import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import queryString from 'query-string'
import {
  type IDeleteReportDetailsMutationRequest,
  type IGetReportDetailsQueryRequest,
  type IGetReportDetailsQueryResponse,
  type IPostFlyForwardRequest,
  type IPostFlyForwardResponse,
  type IPostInitMultiAssetReportMutationRequest,
  type IPostInitMultiAssetReportMutationResponse,
  type IPutReportWithNameMutationRequest,
} from './types'

const reportsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getReportDetails: builder.query<IGetReportDetailsQueryResponse, IGetReportDetailsQueryRequest>({
      query: ({ reportId }) => ({
        url: APIRoutes.ForecastModule.ReportsController.GetSingleReport(reportId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => [{ type: 'reports', id: request.reportId }],
    }),
    initMultiAssetReport: builder.mutation<IPostInitMultiAssetReportMutationResponse, IPostInitMultiAssetReportMutationRequest>({
      query: ({ assetIds }) => {
        const params = queryString.stringify({ assetIds }, { arrayFormat: 'none' })
        return {
          url: `${APIRoutes.ForecastModule.FlyForwardController.InitMultiAssetsFly()}?${params}`,
          method: 'POST',
        }
      },
    }),
    flyForward: builder.mutation<IPostFlyForwardResponse, IPostFlyForwardRequest>({
      query: ({ reportItemId, assetType, flyForwardOverrideParams }) => {
        const params = `assetType=${assetType}`

        return {
          url: `${APIRoutes.ForecastModule.FlyForwardController.FlyForward(reportItemId)}?${params}`,
          method: 'POST',
          data: flyForwardOverrideParams,
        }
      },
    }),
    saveReportWithName: builder.mutation<string, IPutReportWithNameMutationRequest>({
      query: ({ id, name }) => ({
        url: APIRoutes.ForecastModule.ReportsController.Save(id),
        method: 'PUT',
        data: name,
      }),
      invalidatesTags: (_, __, _request) => [{ type: 'reportsSearch' }, { type: 'userNames' }],
    }),
    deleteReport: builder.mutation<void, IDeleteReportDetailsMutationRequest>({
      query: ({ reportId }) => ({
        url: APIRoutes.ForecastModule.ReportsController.Delete(reportId),
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, _request) => [{ type: 'reportsSearch' }, { type: 'userNames' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetReportDetailsQuery,
  useInitMultiAssetReportMutation,
  useFlyForwardMutation,
  useSaveReportWithNameMutation,
  useDeleteReportMutation,
  util: reportsApiSliceUtil,
} = reportsApi

type Endpoints = typeof reportsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TReportsApiActions = InitiateActions[keyof InitiateActions]
