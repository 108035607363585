import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import { type IGetAllAssetsBySearchQueryRequest, type IGetAllAssetsBySearchQueryResponse } from './types'

const assetsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAssetsBySearch: builder.query<IGetAllAssetsBySearchQueryResponse, IGetAllAssetsBySearchQueryRequest>({
      query: ({ searchTerm, expand, assetType }) => ({
        url: APIRoutes.AssetsModule.AssetsController.GetAllAssets(),
        params: { expand, SerialNumber: searchTerm, AssetType: assetType },
        method: 'GET',
      }),
      providesTags: (_, __, request) => [{ type: 'assets', id: `${request.assetType}-${request.searchTerm}` }],
    }),
  }),
  overrideExisting: false,
})

export const { useGetAllAssetsBySearchQuery, util: assetsApiSliceUtil } = assetsApi

type Endpoints = typeof assetsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TAssetsApiActions = InitiateActions[keyof InitiateActions]
