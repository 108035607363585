import { useReactTable, type ColumnDef, getCoreRowModel, flexRender } from '@tanstack/react-table'
import { type IMaintenanceScheduleDisplay } from '../../../models/entities/MaintenanceSchedule'
import { YearMonth, type IYearMonth, SimpleCell, type CheckTypes, CheckTypesDisplay, cn } from '@flyward/platform'
import { useMemo } from 'react'
import { cloneDeep } from 'lodash'

const getFormattedValue = (getValue: () => unknown) => {
  const value = getValue()
  return value as string
}

const getMonthYear = (getValue: () => unknown) => {
  const value: IYearMonth = getValue() as IYearMonth
  const yearMonth: YearMonth = new YearMonth(value.year, value.month)
  return yearMonth.toString()
}

const getCheckType = (getValue: () => unknown) => {
  const value = getValue() as CheckTypes
  return CheckTypesDisplay(value)
}

const columns: Array<ColumnDef<IMaintenanceScheduleDisplay>> = [
  {
    accessorKey: 'yearMonth',
    header: () => 'Month',
    cell: ({ getValue }) => <SimpleCell>{getMonthYear(getValue)}</SimpleCell>,
  },
  {
    accessorKey: 'forecastedComponent.checkType',
    header: () => 'Check Type',
    cell: ({ getValue }) => <SimpleCell className="font-semibold">{getCheckType(getValue)}</SimpleCell>,
  },
  {
    accessorKey: 'componentSerialNumber',
    header: () => 'Component',
    cell: ({ getValue }) => <SimpleCell className="font-semibold">{getFormattedValue(getValue)}</SimpleCell>,
  },
]

interface IAssetScheduleTableProps {
  schedules: IMaintenanceScheduleDisplay[] | undefined
  isLoading: boolean
}

export const AssetScheduleTable = ({ schedules = [], isLoading }: IAssetScheduleTableProps) => {
  const assetSchedules = useMemo(() => {
    return cloneDeep(schedules)
      .slice()
      .sort((a, b) => {
        const yearDiff = (a.yearMonth as IYearMonth).year - (b.yearMonth as IYearMonth).year
        if (yearDiff !== 0) {
          return yearDiff
        } else {
          return (a.yearMonth as IYearMonth).month - (b.yearMonth as IYearMonth).month
        }
      })
  }, [schedules])

  const table = useReactTable<IMaintenanceScheduleDisplay>({
    data: assetSchedules,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return schedules.length === 0 || isLoading ? (
    <></>
  ) : (
    <div className="block w-full overflow-x-auto">
      <table className="m-0 w-full p-0">
        <thead className=" bg-header-table">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="px-4 py-2">
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan} className="pb-2 pt-6 text-left text-sm">
                    {header.isPlaceholder ? null : <div className="pl-2">{flexRender(header.column.columnDef.header, header.getContext())}</div>}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => {
            return (
              <tr key={row.id} className={cn(`gap-x-10 px-4 py-2`, index % 2 === 0 ? 'bg-row-even' : 'bg-row-odd')}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className="">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
