import { useGetAllAssetsBySearchQuery } from '@flyward/assets/store'
import { PageContainer } from '@flyward/main-app/layout'
import { clearAll } from '@flyward/platform/store'
import { useAppDispatch } from '@flyward/platform/store/configureHooks'
import { isNil } from 'lodash'
import { useEffect } from 'react'
import { FlyForwardPageHeader } from './FlyForwardPageHeader/FlyForwardPageHeader'
import { FlyForwardPageBody } from './FlyForwardPageBody/FlyForwardPageBody'
import { useHeaderContent } from '@flyward/platform'

const FlyForwardPage = () => {
  const { data: allAssetsData, isLoading } = useGetAllAssetsBySearchQuery({
    searchTerm: undefined,
    expand: true,
  })

  const { setCustomElements } = useHeaderContent()

  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(clearAll())
      setCustomElements([])
    }
  }, [])

  if (isLoading || isNil(allAssetsData) || allAssetsData.items.length === 0) {
    return <div data-testid="fly-forward-loading"></div>
  }

  return (
    <PageContainer>
      <FlyForwardPageHeader />
      <FlyForwardPageBody allAssetsData={allAssetsData} />
    </PageContainer>
  )
}

export { FlyForwardPage }
