import { IconVariant } from '@flyward/platform'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ProtectedRoutes } from '../layout'
import {
  AircraftPage,
  AirframeMaintenanceProgramPage,
  AuxiliaryPowerUnitMaintenanceProgramPage,
  EngineMaintenanceProgramPage,
  EnginePage,
  FleetPage,
  FlyForwardPage,
  HomePage,
  KnowledgeBasePage,
  LandingGearMaintenanceProgramPage,
  LogIn,
  NotFoundErrorPage,
  ReportPage,
  ReportsPage,
  UnauthorizedErrorPage,
  UserInformationWrapper,
  UserManagement,
} from '../pages'
import { AuthDone, AuthFailed } from '../pages/Auth'
import { TestPage } from '../pages/Test'

export const RouterProvider = () => {
  const routes = [
    { label: 'Fleet Summary', path: '/fleet', icon: IconVariant.LocalAirport },
    { label: 'Fly Forward', path: '/flyforward', icon: IconVariant.FlightTakeOff },
    { label: 'Reports', path: '/reports', icon: IconVariant.NewListAlt },
    { label: 'Knowledge Base', path: '/knowledge-base', icon: IconVariant.Settings },
  ]

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route path="/auth-done" element={<AuthDone />} />
      <Route path="/auth-failed" element={<AuthFailed />} />
      <Route element={<ProtectedRoutes routes={routes} />}>
        <Route path="/fleet" element={<FleetPage />} />
        <Route path="/fleet/aircrafts/:id" element={<AircraftPage />} />
        <Route path="/fleet/engines/:id" element={<EnginePage />} />
        <Route path="/flyforward" element={<FlyForwardPage />} />
        <Route path="/flyforward/:assetIds" element={<FlyForwardPage />} />
        <Route path="/reports" element={<ReportsPage />} />
        <Route path="/reports/:reportId" element={<ReportPage />} />
        <Route path="/knowledge-base" element={<Navigate replace to="/knowledge-base/engine-maintenance-program" />} />
        <Route path="/knowledge-base/engine-maintenance-program" element={<KnowledgeBasePage />} />
        <Route path="/knowledge-base/airframe-maintenance-program" element={<KnowledgeBasePage />} />
        <Route path="/knowledge-base/auxiliary-power-unit-maintenance-program" element={<KnowledgeBasePage />} />
        <Route path="/knowledge-base/landing-gears-maintenance-program" element={<KnowledgeBasePage />} />
        <Route path={`/knowledge-base/airframe-maintenance-program/:id`} element={<AirframeMaintenanceProgramPage />} />
        <Route path={`/knowledge-base/engine-maintenance-program/:id`} element={<EngineMaintenanceProgramPage />} />
        <Route path={`/knowledge-base/landing-gears-maintenance-program/:id`} element={<LandingGearMaintenanceProgramPage />} />
        <Route path={`/knowledge-base/auxiliary-power-unit-maintenance-program/:id`} element={<AuxiliaryPowerUnitMaintenanceProgramPage />} />
        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/test" element={<TestPage />} />
      </Route>
      <Route path="/home" element={<HomePage routes={routes} />}>
        <Route path="user-info" element={<UserInformationWrapper />} />
        <Route path="login" element={<LogIn />} />
        <Route path="" element={<Navigate to="/home/login" />} />
      </Route>
      <Route path="/unauthorized" element={<UnauthorizedErrorPage />} />
      <Route path="*" element={<NotFoundErrorPage />} />
    </Routes>
  )
}
