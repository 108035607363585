import { IconVariant } from './IconVariant'
import {
  AccountCircle,
  Add,
  AddCircle,
  ArrowBack,
  ArrowDown,
  ArrowForward,
  ArrowUp,
  Calendar,
  Clear,
  Create,
  Delete,
  Email,
  Engine,
  ErrorIcon,
  ExpandLess,
  ExpandMore,
  FilterList,
  FlightTakeOff,
  FullScreen,
  EmptySvg,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ListAlt,
  LocalAirport,
  LogOut,
  Menu,
  MenuOpen,
  NewListAlt,
  PlaneZoomOut,
  Refresh,
  RemoveCircle,
  Save,
  Schedule,
  Search,
  Settings,
  Success,
  Tick,
  ToggleOff,
  ToggleOn,
  UploadFile,
  UserManagement,
} from './svg'

export const icons = {
  [IconVariant.AccountCircle]: AccountCircle,
  [IconVariant.Add]: Add,
  [IconVariant.AddCircle]: AddCircle,
  [IconVariant.ArrowBack]: ArrowBack,
  [IconVariant.ArrowForward]: ArrowForward,
  [IconVariant.ArrowUp]: ArrowUp,
  [IconVariant.ArrowDown]: ArrowDown,
  [IconVariant.Calendar]: Calendar,
  [IconVariant.Clear]: Clear,
  [IconVariant.Create]: Create,
  [IconVariant.ExpandMore]: ExpandMore,
  [IconVariant.ExpandLess]: ExpandLess,
  [IconVariant.Delete]: Delete,
  [IconVariant.Email]: Email,
  [IconVariant.Engine]: Engine,
  [IconVariant.Error]: ErrorIcon,
  [IconVariant.FilterList]: FilterList,
  [IconVariant.FlightTakeOff]: FlightTakeOff,
  [IconVariant.FullScreen]: FullScreen,
  [IconVariant.EmptySvg]: EmptySvg,
  [IconVariant.KeyboardArrowLeft]: KeyboardArrowLeft,
  [IconVariant.KeyboardArrowRight]: KeyboardArrowRight,
  [IconVariant.ListAlt]: ListAlt,
  [IconVariant.LocalAirport]: LocalAirport,
  [IconVariant.LogOut]: LogOut,
  [IconVariant.Menu]: Menu,
  [IconVariant.MenuOpen]: MenuOpen,
  [IconVariant.NewListAlt]: NewListAlt,
  [IconVariant.PlaneZoomOut]: PlaneZoomOut,
  [IconVariant.Refresh]: Refresh,
  [IconVariant.RemoveCircle]: RemoveCircle,
  [IconVariant.Save]: Save,
  [IconVariant.Schedule]: Schedule,
  [IconVariant.Search]: Search,
  [IconVariant.Settings]: Settings,
  [IconVariant.Success]: Success,
  [IconVariant.Tick]: Tick,
  [IconVariant.ToggleOff]: ToggleOff,
  [IconVariant.ToggleOn]: ToggleOn,
  [IconVariant.UploadFile]: UploadFile,
  [IconVariant.UserManagement]: UserManagement,
}
