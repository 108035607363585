import { type Column, type ColumnDef } from '@tanstack/react-table'
import {
  NumberDisplay,
  NumberDisplayType,
  CheckTypes,
  CheckTypesTableDisplay,
  type GroupedComponentMonthlyStatistics,
  Button,
  ButtonVariant,
  IconVariant,
  cn,
  type MonthlyStatistics,
  YearMonth,
  type ComponentMonthlyStatistics,
  ComponentCategory,
  Size,
  UtilizationUnits,
  UtilizationUnitsDisplay,
  Tooltip,
} from '@flyward/platform'
import { type CSSProperties } from 'react'
import { isNil } from 'lodash'

const getUnitForCheckType = (checkType: CheckTypes): UtilizationUnits => {
  switch (checkType) {
    case CheckTypes.EngineLifeLimitedPartReplacement:
      return UtilizationUnits.FlightCycles
    case CheckTypes.AuxiliaryPowerUnitPerformanceRestoration:
      return UtilizationUnits.AuxiliaryPowerUnitHours
    default:
      return UtilizationUnits.FlightHours
  }
}

export const getMonthlyStatisticsDisplayLabels = (item: ComponentMonthlyStatistics): string[] =>
  item.monthlyStatistics.map((monthlyStatistics) => YearMonth.fromObject(monthlyStatistics.yearMonth).toShortString())

const generateColumn = (
  propertyName: keyof MonthlyStatistics,
  header: string,
  index: number,
  isMasterComponent: boolean,
): ColumnDef<ComponentMonthlyStatistics> => {
  const column: ColumnDef<ComponentMonthlyStatistics> = {
    id: `${index}.${propertyName as string}`,
    accessorFn: (row: ComponentMonthlyStatistics) => {
      if (propertyName in row.monthlyStatistics[index]) {
        const monthlyStatistics = row.monthlyStatistics[index]

        if (propertyName === 'forecastedMileage') {
          if (isMasterComponent) {
            const utilizationUnit = getUnitForCheckType(row.forecastedComponent.checkType)
            const mileages = monthlyStatistics[propertyName]!
            const mileage = mileages?.find((m) => m.utilizationUnit === utilizationUnit)
            return mileage?.utilizationAmount
          } else {
            return monthlyStatistics.utilizationSlsv
          }
        }

        return monthlyStatistics[propertyName] as number
      }

      return '-'
    },
    header: () => <span>{header}</span>,
    cell: ({ getValue, row }) => {
      if (propertyName === 'forecastedMileage') {
        const utilizationUnit = getUnitForCheckType(row.original.forecastedComponent.checkType)

        const value = getValue()

        return !isNil(value) ? (
          <span>
            <Tooltip
              content={<NumberDisplay displayType={NumberDisplayType.Integer} value={getValue()} />}
              tooltipContent={UtilizationUnitsDisplay(utilizationUnit)}
            />
          </span>
        ) : (
          <NumberDisplay displayType={NumberDisplayType.Integer} value={getValue()} />
        )
      }
      return <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={getValue()} />
    },
  }
  return column
}

export const generateColumnsForGroupedComponents = (
  propertyName: keyof MonthlyStatistics,
  item: GroupedComponentMonthlyStatistics,
): Array<ColumnDef<GroupedComponentMonthlyStatistics>> => [
  {
    id: 'forecastedComponent-checkType',
    accessorKey: 'forecastedComponent.checkType',
    header: () => null,
    cell: ({ getValue, row }) => {
      const groupedComponentMonthlyStatistics = row.original
      if (groupedComponentMonthlyStatistics.forecastedComponent.checkType === CheckTypes.EngineLifeLimitedPartReplacement) {
        return (
          <Button
            variant={ButtonVariant.Ghost}
            onClick={row.getToggleExpandedHandler()}
            className={cn('m-0 h-4 w-full p-0 text-xs', `${row.getIsExpanded() ? 'text-text-4' : 'text-text-1'}`)}
            leftIconClassName={cn(`group-hover:text-primary-light-1`, row.getIsExpanded() ? 'text-text-4' : 'text-primary')}
            leftIcon={row.getIsExpanded() ? IconVariant.RemoveCircle : IconVariant.AddCircle}
            label={CheckTypesTableDisplay(getValue() as CheckTypes)}
            size={Size.Small}
          />
        )
      }
      let value = CheckTypesTableDisplay(getValue() as CheckTypes)
      if (
        groupedComponentMonthlyStatistics.forecastedComponent.checkType === CheckTypes.LandingGearOverhaul &&
        groupedComponentMonthlyStatistics.forecastedComponent.componentCategory === ComponentCategory.AuxiliaryPowerUnit
      ) {
        value = 'Total'
      }
      return <div className="text-balance">{value}</div>
    },
  },
  ...getMonthlyStatisticsDisplayLabels(item).reduce<Array<ColumnDef<GroupedComponentMonthlyStatistics>>>((acc, label, index) => {
    const column = generateColumn(propertyName, label, index, true) as ColumnDef<GroupedComponentMonthlyStatistics>
    acc.push(column)

    return acc
  }, []),
]

export const generateColumnsForSubComponents = (
  propertyName: keyof MonthlyStatistics,
  item: ComponentMonthlyStatistics,
): Array<ColumnDef<ComponentMonthlyStatistics>> => [
  {
    id: 'forecastedComponent-componentModel',
    accessorKey: 'forecastedComponent.componentModel',
    header: () => null,
    cell: ({ getValue }) => <>{`${getValue() as string}`}</>,
  },
  ...getMonthlyStatisticsDisplayLabels(item).reduce<Array<ColumnDef<ComponentMonthlyStatistics>>>((acc, label, index) => {
    const column = generateColumn(propertyName, label, index, false)
    acc.push(column)

    return acc
  }, []),
]

export const getCommonPinningStyles = <T,>(column?: Column<T>, showBorder: boolean = true): CSSProperties => {
  const isPinned = column?.getIsPinned()

  return {
    boxShadow: showBorder && isPinned !== false ? '-3px 0 4px -4px gray inset' : undefined,
    left: isPinned !== false ? `${column?.getStart('left')}px` : undefined,
    position: isPinned !== false ? 'sticky' : 'relative',
    // zIndex: isPinned !== false ? 1 : 0,
  }
}
